import Store from '/data/store'
import { Colors, Formats } from './common'

export const auth = false

export const portal = {
  logo: '/logos/entite/alm_2c.svg',
  images: [
    '/portal/alm-1.jpg',
    '/portal/alm-2.jpg'
  ]
}

export const api = {
  // arnaudjuracek/svg-to-pdf does not handle clip-path
  pdfMode: 'rasterize'
}

export const uploads = {
  formats: true,
  templates: false,
  logos: false,
  icons: {
    multiple: true,
    accept: 'image/png, image/jpeg, image/svg+xml'
  },
  textures: {
    multiple: true,
    accept: 'image/png, image/jpeg'
  }
}

export const animation = {
  easing: 'expoInOut',
  duration: '2s',
  blocks: {
    image: ['fade', 'fall', 'pan', 'pop', 'rise', 'pulse'],
    text: ['fade', 'fall', 'pan', 'pop', 'rise', 'typewriter', 'shift', 'pulse']
  }
}

export const templates = [
  { label: 'gabarit simple', src: '/templates/alm/default/index.html', preview: { src: '/templates/alm/default/preview.png', ratio: 1200 / 600 } },
  { label: 'gabarit sans logo', src: '/templates/alm/default-no-logo/index.html', preview: { src: '/templates/alm/default-no-logo/preview.png', ratio: 1200 / 600 } },
  { label: 'gabarit double', src: '/templates/alm/double/index.html', preview: { src: '/templates/alm/double/preview.png', ratio: 1200 / 600 } },
  { label: 'cartouche', src: '/templates/alm/cartouche/index.html', preview: { src: '/templates/alm/cartouche/preview.png', ratio: 1200 / 600 } },
  { label: 'logo', src: '/templates/alm/logo/index.html', preview: { src: '/templates/alm/logo/preview.png', ratio: 1200 / 600 } },
  { label: 'gabarit vide', src: '/templates/alm/empty/index.html', preview: { src: '/templates/alm/empty/preview.png', ratio: 1200 / 600 } },
  { label: 'papier tourné à gauche', src: '/templates/alm/rotate-left/index.html', preview: { src: '/templates/alm/rotate-left/preview.png', ratio: 1200 / 600 } },
  { label: 'papier tourné à droite', src: '/templates/alm/rotate-right/index.html', preview: { src: '/templates/alm/rotate-right/preview.png', ratio: 1200 / 600 } },
  { label: '2 papiers empilés', src: '/templates/alm/stacked/index.html', preview: { src: '/templates/alm/stacked/preview.png', ratio: 1200 / 600 } },
  { label: '3 papiers empilés', src: '/templates/alm/stacked-3/index.html', preview: { src: '/templates/alm/stacked-3/preview.png', ratio: 1200 / 600 } },
  { label: '4 papiers empilés', src: '/templates/alm/stacked-4/index.html', preview: { src: '/templates/alm/stacked-4/preview.png', ratio: 1200 / 600 } }
]

export const galleries = {
  logos: [
    { group: 'entité', sources: { black: '/logos/entite/alm_1c.svg', white: '/logos/entite/alm_white.svg', color: '/logos/entite/alm_2c.svg' }, preview: { src: '/logos/entite/alm_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/feva_1c.svg', white: '/logos/entite/feva_white.svg', color: '/logos/entite/feva_2c.svg' }, preview: { src: '/logos/entite/feva_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/fe_1c.svg', white: '/logos/entite/fe_white.svg', color: '/logos/entite/fe_2c.svg' }, preview: { src: '/logos/entite/fe_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/pmd_1c.svg', white: '/logos/entite/pmd_white.svg', color: '/logos/entite/pmd_2c.svg' }, preview: { src: '/logos/entite/pmd_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ersp_1c.svg', white: '/logos/entite/ersp_white.svg', color: '/logos/entite/ersp_2c.svg' }, preview: { src: '/logos/entite/ersp_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ga_1c.svg', white: '/logos/entite/ga_white.svg', color: '/logos/entite/ga_2c.svg' }, preview: { src: '/logos/entite/ga_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/alm_1c.svg', white: '/logos/appartenance/alm_white.svg', color: '/logos/appartenance/alm_2c.svg' }, preview: { src: '/logos/appartenance/alm_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/pd_1c.svg', white: '/logos/appartenance/pd_white.svg', color: '/logos/appartenance/pd_2c.svg' }, preview: { src: '/logos/appartenance/pd_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/ppdla_1c.svg', white: '/logos/appartenance/ppdla_white.svg', color: '/logos/appartenance/ppdla_2c.svg' }, preview: { src: '/logos/appartenance/ppdla_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/aidons_les_notres_1c.svg', white: '/logos/autres-marques-du-groupe/aidons_les_notres_white.svg', color: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/agmut_1c.svg', white: '/logos/autres-marques-du-groupe/agmut_white.svg', color: '/logos/autres-marques-du-groupe/agmut_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/agmut_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/arpege_1c.svg', white: '/logos/autres-marques-du-groupe/arpege_white.svg', color: '/logos/autres-marques-du-groupe/arpege_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/arpege_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/mdpj_1c.svg', white: '/logos/autres-marques-du-groupe/mdpj_white.svg', color: '/logos/autres-marques-du-groupe/mdpj_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/mdpj_2c.svg' } }
  ],

  icons: (() => {
    // Import *_white icons just so that vite registers them for the build stage
    import.meta.glob('@assets/icons/*_white.svg', { eager: true, as: 'url' })
    return Object.values(import.meta.glob('@assets/icons/*_black.svg', { eager: true, as: 'url' })).map(src => ({
      group: 'pictogrammes',
      sources: { black: src, white: src.replace('_black.svg', '_white.svg'), color: src },
      preview: { src }
    }))
  })(),

  textures: [
    ...(Store.env.debug.get() ? Object.values(import.meta.glob('@assets/debug/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'debug', src, preview: { ratio: 1 } })) : []),
    ...Object.values(import.meta.glob('@assets/alm/peoples/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'personnes', src, preview: { ratio: 1 } })),
    ...Object.values(import.meta.glob('@assets/alm/textures/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'textures', src, preview: { ratio: 1 } }))
  ]
}

export const colors = [
  Colors.brown,
  Colors.gray,
  ...Object.values(Colors.primary),
  Colors.white
]

export const formats = [
  Formats.debug,
  Formats.facebook,
  Formats.instagram,
  Formats.x,
  Formats.linkedin,
  Formats.print
]
  .filter(formats => formats.length > 0)
  .reduce((formats, group) => [...formats, ...group], [])

export const defaults = {
  logo: galleries.logos[0],
  front: colors[0],
  back: colors[2],
  background: colors[colors.length - 1]
}

import './Foldable.scss'
import * as Transform from 'transformation-matrix'
import { Component } from '@tooooools/ui'
import { ensure, writable } from '@tooooools/ui/state'

import Store from '/data/store'

import Foldable from '/abstractions/Foldable'

import Context from '/components/Context'
import Handle from '/components/Handle'
import FoldRenderer from '/renderers/Fold'

import getScaledBoundingClientRect from '/utils/dom-get-scaled-bounding-client-rect'
import noop from '/utils/noop'

export default class FoldableComponent extends Component {
  beforeRender (props) {
    this.update = this.update.bind(this)
    this.handleDrag = this.handleDrag.bind(this)
    this.handleRelease = this.handleRelease.bind(this)

    this.state = {
      folded: ensure(writable)(props['store-folded'] ?? props.folded)
    }
  }

  template (props, state) {
    return (
      <Context name='part:back'>
        <div class='foldable-handles no-export'>
          {new Array(4).fill(true).map((_, index) => (
            <Handle
              ref={this.refArray('handles')}
              data-title='Déplacer pour plier le document, double-cliquer pour défaire le pli'
              event-move={this.handleDrag}
              event-release={this.handleRelease}
              event-dblclick={this.reset(index)}
            />
          ))}
        </div>
      </Context>
    )
  }

  afterRender () {
    const slot = this.props['template-slot']?.get()
    this.refs.foldable = new Foldable(this.props.target, slot)

    // Render a <FoldRenderer> component in charge of displaying fold geometry
    this.render((
      <FoldRenderer
        id={this.refs.foldable.id}
        ref={this.ref('renderer')}
        event-update={this.props['event-update']}
      />
    ), this.props.target)

    // Attach a Handle to each FoldRenderer corner <image>
    this.refs.renderer.refs.images.forEach((image, index) => this.render((
      <Context name='part:back'>
        <Handle
          hydrate={image}
          data-title='Glisser-déplacer pour déplacer l’image de texture, utiliser la molette pour zoomer, double-cliquer pour remettre à zéro'
          event-move={([dx, dy]) => this.refs.foldable.update(index, corner => {
            const theta = corner.angle
            const offx = Math.sin(theta + Math.PI / 2) * dx + Math.sin(theta) * dy
            const offy = Math.cos(theta + Math.PI / 2) * dx + Math.cos(theta) * dy

            corner.data.imageOffsetX = (corner.data.imageOffsetX ?? 0) + offx
            corner.data.imageOffsetY = (corner.data.imageOffsetY ?? 0) + offy
            this.update()
          })}
          event-wheel={factor => this.refs.foldable.update(index, corner => {
            corner.data.imageZoom = Math.max(1, (corner.data.imageZoom ?? 1) * factor)
            this.update()
            // TODO debounce
            ;(this.props['event-release'] ?? noop)(null, this)
          })}
          event-dblclick={() => this.refs.foldable.update(index, corner => {
            delete corner.data.imageOffsetX
            delete corner.data.imageOffsetY
            delete corner.data.imageZoom
            this.update()
          })}
          event-release={() => {
            ;(this.props['event-release'] ?? noop)(null, this)
          }}
        />
      </Context>
    ), image.parentNode))

    // Decorate the target with a class and a clipPath
    this.props.target.classList.add('foldable')
    this.props.target.style.clipPath = `url(#${this.refs.foldable.id}-clipPath)`
  }

  reset (cornerIndex) {
    return e => {
      this.refs.foldable.corners[cornerIndex].reset()
      this.update()
    }
  }

  update () {
    this.refs.foldable.solve()
    this.refs.foldable.render(this.refs.renderer)

    // Update handles position to always match their corner position
    this.refs.foldable.forEach(corner => {
      const handle = this.refs.handles[corner.index]
      const [dx, dy] = corner.delta([+(handle.base.dataset.x ?? 0), +(handle.base.dataset.y ?? 0)])
      handle.translate([dx, dy])
      handle.state.disabled.set(corner.data.disabled)
    })

    this.state.folded.set(this.refs.foldable.folded)

    this.props['template-slot']?.update(data => ({ ...data, ...this.refs.foldable.toJSON() }))

    ;(this.props['event-update'] ?? noop)(null, this)
  }

  reflow () {
    // Temporarily remove transformation
    this.props.target.style.transform = ''

    const { width, height } = getScaledBoundingClientRect(this.props.target, Store.app.viewportScale.get())
    this.refs.renderer.resize(width, height)
    this.refs.foldable.resize(width, height)

    this.resolveCustomTransformation()
    this.update()
  }

  // Apply a custom transformation passed as --foldable-transformation in template style
  // IMPORTANT: do not declare CSS transform because it causes issues with geom calc before this step
  resolveCustomTransformation () {
    const style = window.getComputedStyle(this.props.target)
    const value = style.getPropertyValue('--foldable-transform') ?? ''
    this.props.target.style.transform = value

    // Resolve and apply transformation to this <Handle>s container
    const transformation = style.getPropertyValue('transform')
    if (!value || transformation === 'none') {
      this.base.style.transform = ''
      return
    }

    const matrix = Transform.fromString(transformation)
    const { translate, scale, rotation } = Transform.decomposeTSR(matrix)
    const [cx, cy] = style.getPropertyValue('transform-origin')
      .split(' ')
      .map(v => parseFloat(v)) ?? [0, 0]

    this.base.style.transformOrigin = '0 0'
    this.base.style.transform = Transform.toString(
      Transform.compose(
        Transform.translate(translate?.tx ?? 0, translate?.ty ?? 0),
        Transform.scale(scale?.sx ?? 1, scale?.sy ?? scale?.sx ?? 1, cx, cy),
        Transform.rotate(rotation?.angle ?? 0, cx, cy)
      )
    )
  }

  handleDrag ([dx, dy], handle) {
    const index = this.refs.handles.indexOf(handle)

    handle.translate([dx, dy])
    this.refs.foldable.update(index, corner => ({
      timestamp: Date.now(),
      position: [corner.position[0] + dx, corner.position[1] + dy]
    }))

    this.update()
  }

  handleRelease () {
    this.refs.foldable.forEach(corner => corner.snap(50), true)
    this.update()

    ;(this.props['event-release'] ?? noop)(null, this)
  }
}

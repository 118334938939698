/**

'semver': {
  message: '',
  features: [
   'HTML <b>ready</b> item'
  ],
  improvements: [],
  fixes: []
}

*/

export default {
  '2.4.0': {
    message: `
      Cette mise-à-jour intègre les nouvelles couleurs de la charte ALM, ainsi que de nouveaux gabarits pour la charte ALM et la sous-charte marque employeur.
    `,
    features: [
      'Certains gabarits peuvent désormais utiliser des thèmes colorés (ensemble de plusieurs couleurs)',
      'Le style des boutons call-to-action a été mis à jour',
      'Ajout du support du surlignage sur certains gabarits',
      'Ajout du support des bordures sur certains gabarits',
      'Ajout du support des rotations sur certains gabarits',
      'Ajout des formats Instagram'
    ],
    improvements: [
      'Amélioration générale des performances et de la stabilité de l’application',
      'Augmentation de l’espace disponible sur les petits écrans',
      'Amélioration du mode <code>rayons&nbsp;X</code>'
    ],
    fixes: [
      'Correction d’un bug qui changeait légèrement la position de certains blocs au déverrouillage d’un gabarit',
      'Correction d’un bug qui empêchait la restauration d’un document dans un cas particulier'
    ]
  },

  '2.3.1': {
    fixes: [
      'Correction d’un bug qui empêchait l’édition d’un bloc après la suppression d’un gabarit'
    ]
  },

  '2.3.0': {
    message: `
      Cette mise-à-jour importante intègre désormais un contrôle beaucoup plus fin des animations, et permet de sélectionner pour chaque élément de la page une animation différente et de régler son temps d’apparition.
    `,
    features: [
      'Chaque élément de la page peut désormais être animé indépendamment',
      'Ajout du mode <code>picture-in-picture</code> pour le panneau de prévisualisation'
    ],
    improvements: [
      'Amélioration des performances durant le réglage de l’échelle sur les textures'
    ],
    fixes: [
      'Correction d’un bug qui restaurait certains éléments d’une page au rechargement du document'
    ]
  },

  '2.2.0': {
    improvements: [
      'Des statistiques d’utilisation sont désormais collectées anonymement'
    ]
  },

  '2.1.7': {
    fixes: [
      'Correction d’un bug empêchant l’export de plis contenant des images personnalisées'
    ]
  },

  '2.1.6': {
    fixes: [
      'Correction d’un bug empêchant l’export de plis contenant des images personnalisées'
    ]
  },

  '2.1.5': {
    improvements: [
      'Lors de l’ajout de pictogramme personnalisé, il est désormais possible de choisir un fichier <code>PNG</code> ou <code>JPG</code> en plus du format <code>SVG</code>'
    ],
    fixes: [
      'Correction d’un bug empêchant l’ajout de pictogrammes personnalisés'
    ]
  },

  '2.1.4': {
    fixes: [
      'Correction d’un bug empêchant le chargement correct d’un fichier <code>.alm</code> contenant des images personnalisées'
    ]
  },

  '2.1.3': {
    // As per client request, redirect / to /alm. Portal is now accesible via /portail
    improvements: [
      'Les dimensions d’un format customisé sont désormais automatiquement arrondies pour les formats en pixels'
    ]
  },

  '2.1.2': {
    fixes: [
      'Correction d’un bug empêchant la création de formats imprimés avec des dimensions impaires'
    ]
  },

  '2.1.1': {
    improvements: [
      'Mise-à-jour des logos',
      'Amélioration du moteur de rendu <code>PDF</code>. Le fichier <code>PDF</code> est désormais généré à la volée plutôt que par la boîte de dialogue d’impression du navigateur',
      'Les boutons d’export de formats animés (<code>MP4</code>, <code>GIF</code>) sont désormais désactivés lorsque le format sélectionné est un format d’impression'
    ]
  },

  '2.1.0': {
    features: [
      'Ajout de la visite guidée, qui se déclenche automatiquement à la première visite d’un nouvel utilisateur. La visite guidée peut être relancé à n’importe quel moment en cliquant sur le bouton <code>?</code> en bas à droite dans la barre d’état',
      'Il est désormais possible d’ajouter à une page des call-to-action personnalisés'
    ],
    improvements: [
      'Amélioration de la gestion des mots qui dépassent des blocs textes',
      'Amélioration de la restauration de session',
      'Amélioration du chargement des fichiers sources',
      'Amélioration de l’ouverture automatique des tickets de suivi en cas de bug',
      'Mise-à-jour des dépendances de l’application'
    ],
    fixes: [
      'Correction d’un bug durant lequel les pictos ne prennaient pas la bonne couleur',
      'Correction d’un bug qui restauraient des éléments précédemment supprimés',
      'Amélioration générale des performances et de la stabilité de l’application'
    ]
  },

  '2.0.0-rc5': {
    features: [
      'L’application conserve désormais la session de travail précédente, restaurée automatiquement à chaque rechargement de la page',
      'Ajout des couleurs pastels dans la charte haut de gamme'
    ],
    improvements: [
      'Mise-à-jour des dépendances de l’application'
    ],
    fixes: [
      'Amélioration générale des performances et de la stabilité de l’application'
    ]
  },

  '2.0.0-rc4': {
    features: [
      'La façon dont les couleurs et les images de textures sont appliquées au document a été améliorée : cliquer sur une partie du document (recto, verso, arrière-plan…) affiche désormais une barre d’outils contextuelle spécifique',
      'Il est désormais possible d’exporter le document au format <code>SVG</code>',
      'Dans les gabarits qui le supportent, il est désormais possible d’ajouter des éléments flottants (textes, pictogrammes) dans chaque partie spécifique du document, et non seulement dans une partie imposée',
      'Dans les gabarits qui le supportent, un mode <code>rayons&nbsp;X</code> permet désormais de désactiver temporairement le premier plan afin d’afficher d’éventuels éléments cachés, et ainsi de faciliter leur manipulation. Ajouter un élément flottant (texte, pictogramme) en arrière-plan active automatiquement le mode <code>rayons&nbsp;X</code>.',
      'Sélectionner un format personnalisé affiche désormais un bouton permettant la modification du format en question',
      'Un message d’avertissement s’affiche désormais lorsque la page est sur le point d’être quittée',
      'L’application est désormais enregistrée sur <a href="https://sentry.io" target="_blank">Sentry.io</a>, chaque message d’erreur fera l’objet de l’ouverture automatique d’un ticket du suivi à des fins de maintenance'
    ],
    improvements: [
      'Les mots très longs retournent désormais à la ligne au lieu de dépasser du document',
      'Le menu de sélection du format a été amélioré',
      'Les formats nommés <code>Twitter</code> ont été renommés en <code>X</code>',
      'Mise-à-jour des dépendances de l’application'
    ],
    fixes: [
      'La durée de chaque page dans la vue en grille est désormais correctement affichée',
      'Correction d’un cas particulier durant lequel l’enregistrement d’un fichier <code>.alm</code> donnait un fichier vide',
      'Charger un fichier <code>.alm</code> contenant plusieurs pages fonctionne désormais correctement',
      'Charger un fichier <code>.alm</code> contenant des images personnalisées fonctionne désormais correctement',
      'L’ombre des vignettes en cours de déplacement dans la vue en grille est désormais correctement positionnée',
      'Amélioration générale des performances et de la stabilité de l’application'
    ]
  },

  '2.0.0-rc3': {
    features: [
      'L’enregistrement et le chargement de fichier au format <code>.alm</code> est désormais disponible',
      'Le menu déroulant des formats permet désormais d’ajouter des formats personnalisés',
      'Il est désormais possible de dupliquer une page',
      'Dans certains gabarits il est désormais possible d’ajouter des textes flottants',
      'Dans certains gabarits il est désormais possible d’ajouter des images flottantes, et notamment des pictogrammes',
      'Il est désormais possible de glisser une image dans la galerie pour l’ajouter aux fichiers personnalisés (si la galerie autorise l’ajout de fichiers personnalisés)'
    ],
    improvements: [
      'Les gabarits ont été améliorés graphiquement et certaines fonctionnalités lissées',
      'Des formats basiques d’impression ont été ajoutés',
      'Les dimensions du document sont désormais affichés dans la barre d’état'
    ],
    fixes: [
      'Correction d’un bug qui empêchait l’export vidéo dans un cas particulier (format avec une hauteur impaire)',
      'Correction d’un bug qui empêchait de zoomer correctement dans la texture d’un pli',
      'Amélioration générale des performances et de la stabilité de l’application'
    ]
  },

  '2.0.0-rc2': {
    fixes: [
      'Double-cliquer sur un mot dans un bloc texte sélectionne désormais correctement le bon mot',
      'Exporter en MP4 ou en GIF un document avec des images dans les plis fonctionne désormais correctement'
    ]
  },

  '2.0.0-rc1': {
    message: 'Version initiale'
  }
}

import Store from '/data/store'
import { Colors, Formats } from './common'

export const auth = false
export const portal = {
  logo: '/logos/entite/alm_1c.svg',
  images: [
    '/portal/marque-employeur-1.png',
    '/portal/marque-employeur-2.png'
  ]
}

export const api = {
  // arnaudjuracek/svg-to-pdf does not handle clip-path
  pdfMode: 'rasterize'
}

export const uploads = {
  formats: true,
  templates: false,
  logos: false,
  icons: {
    multiple: true,
    accept: 'image/png, image/jpeg, image/svg+xml'
  },
  textures: {
    multiple: true,
    accept: 'image/png, image/jpeg'
  }
}

export const animation = {
  easing: 'expoInOut',
  duration: '2s',
  blocks: {
    image: ['fade', 'fall', 'pan', 'pop', 'rise', 'pulse'],
    text: ['fade', 'fall', 'pan', 'pop', 'rise', 'typewriter', 'shift', 'pulse']
  }
}

export const templates = [
  { label: 'offre d’emploi', src: '/templates/marque-employeur/offre-d-emploi/index.html', preview: { src: '/templates/marque-employeur/offre-d-emploi/preview.png', ratio: 1200 / 600 } },
  { label: 'verbatim collaborateur', src: '/templates/marque-employeur/verbatim/index.html', preview: { src: '/templates/marque-employeur/verbatim/preview.png', ratio: 1200 / 600 } },
  { label: 'photo', src: '/templates/marque-employeur/photo/index.html', preview: { src: '/templates/marque-employeur/photo/preview.png', ratio: 1200 / 600 } },
  { label: 'texte libre', src: '/templates/marque-employeur/texte-libre/index.html', preview: { src: '/templates/marque-employeur/texte-libre/preview.png', ratio: 1200 / 600 } },
  { label: 'chiffre clef', src: '/templates/marque-employeur/chiffre-clef/index.html', preview: { src: '/templates/marque-employeur/chiffre-clef/preview.png', ratio: 1200 / 600 } },
  { label: 'call to action', src: '/templates/marque-employeur/call-to-action/index.html', preview: { src: '/templates/marque-employeur/call-to-action/preview.png', ratio: 1200 / 600 } }
]

export const galleries = {
  logos: [
    { group: 'entité', sources: { black: '/logos/entite/alm_1c.svg', white: '/logos/entite/alm_white.svg', color: '/logos/entite/alm_2c.svg' }, preview: { src: '/logos/entite/alm_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/feva_1c.svg', white: '/logos/entite/feva_white.svg', color: '/logos/entite/feva_2c.svg' }, preview: { src: '/logos/entite/feva_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/fe_1c.svg', white: '/logos/entite/fe_white.svg', color: '/logos/entite/fe_2c.svg' }, preview: { src: '/logos/entite/fe_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/pmd_1c.svg', white: '/logos/entite/pmd_white.svg', color: '/logos/entite/pmd_2c.svg' }, preview: { src: '/logos/entite/pmd_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ersp_1c.svg', white: '/logos/entite/ersp_white.svg', color: '/logos/entite/ersp_2c.svg' }, preview: { src: '/logos/entite/ersp_2c.svg' } },
    { group: 'entité', sources: { black: '/logos/entite/ga_1c.svg', white: '/logos/entite/ga_white.svg', color: '/logos/entite/ga_2c.svg' }, preview: { src: '/logos/entite/ga_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/alm_1c.svg', white: '/logos/appartenance/alm_white.svg', color: '/logos/appartenance/alm_2c.svg' }, preview: { src: '/logos/appartenance/alm_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/pd_1c.svg', white: '/logos/appartenance/pd_white.svg', color: '/logos/appartenance/pd_2c.svg' }, preview: { src: '/logos/appartenance/pd_2c.svg' } },
    { group: 'appartenance', sources: { black: '/logos/appartenance/ppdla_1c.svg', white: '/logos/appartenance/ppdla_white.svg', color: '/logos/appartenance/ppdla_2c.svg' }, preview: { src: '/logos/appartenance/ppdla_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/aidons_les_notres_1c.svg', white: '/logos/autres-marques-du-groupe/aidons_les_notres_white.svg', color: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/aidons_les_notres_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/agmut_1c.svg', white: '/logos/autres-marques-du-groupe/agmut_white.svg', color: '/logos/autres-marques-du-groupe/agmut_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/agmut_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/arpege_1c.svg', white: '/logos/autres-marques-du-groupe/arpege_white.svg', color: '/logos/autres-marques-du-groupe/arpege_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/arpege_2c.svg' } },
    { group: 'autres marques', sources: { black: '/logos/autres-marques-du-groupe/mdpj_1c.svg', white: '/logos/autres-marques-du-groupe/mdpj_white.svg', color: '/logos/autres-marques-du-groupe/mdpj_2c.svg' }, preview: { src: '/logos/autres-marques-du-groupe/mdpj_2c.svg' } }
  ],

  icons: [
    ...(() => {
      // Import *_white icons just so that vite registers them for the build stage
      import.meta.glob('@assets/marque-employeur/icons/*_white.svg', { eager: true, as: 'url' })
      return Object.values(import.meta.glob('@assets/marque-employeur/icons/*_black.svg', { eager: true, as: 'url' })).map(src => ({
        group: 'pictogrammes',
        sources: { black: src, white: src.replace('_black.svg', '_white.svg'), color: src },
        preview: { src }
      }))
    })()
  ],

  peoples: [
    ...Object.values(import.meta.glob('@assets/marque-employeur/metiers/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'filières métiers', src, preview: { ratio: 1 } })),
    ...Object.values(import.meta.glob('@assets/marque-employeur/complementaires/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'visuels complémentaires', src, preview: { ratio: 1 } }))
  ],

  textures: [
    ...(Store.env.debug.get() ? Object.values(import.meta.glob('@assets/debug/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'debug', src, preview: { ratio: 1 } })) : []),
    ...Object.values(import.meta.glob('@assets/alm/peoples/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'personnes', src, preview: { ratio: 1 } }))
  ]
}

export const colors = [
  Colors.primary.purple,
  Colors.primary.pink,
  Colors.primary.darkblue,
  Colors.primary.blue,
  Colors.primary.orange,
  Colors.primary.yellow,
  Colors.primary.darkgreen,
  Colors.primary.green
]

// Schemes allow defining tuples of colors, which will be applied all at once
// Defining schemes will prevent users to select each color separately
export const schemes = [
  { front: 0, back: 1, background: null },
  { front: 1, back: 0, background: null },
  { front: 2, back: 3, background: null },
  { front: 3, back: 2, background: null },
  { front: 4, back: 5, background: null },
  { front: 5, back: 4, background: null },
  { front: 6, back: 7, background: null },
  { front: 7, back: 6, background: null }
]

export const formats = [
  Formats.debug,
  Formats.facebook,
  Formats.instagram,
  Formats.x,
  Formats.linkedin,
  Formats.print
]
  .filter(formats => formats.length > 0)
  .reduce((formats, group) => [...formats, ...group], [])

export const defaults = {
  logo: galleries.logos[0],
  people: galleries.peoples[4],
  front: colors[schemes[0].front],
  back: colors[schemes[0].back],
  background: galleries.textures[0]
}

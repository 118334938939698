import './Page.scss'
import { Component } from '@tooooools/ui'
import { not, derived, readable, writable } from '@tooooools/ui/state'

import Store from '/data/store'
import Data from '/data/static'
import * as Icons from '/data/icons'

import * as Actions from '/controllers/Actions'
import * as BlockController from '/controllers/Block'
import { warn } from '/controllers/Toast'

import {
  Button,
  Input,
  Toolbar
} from '@tooooools/ui/components'
import Block from '/components/Block'
import Context from '/components/Context'
import Foldable from '/components/Foldable'
import Image from '/components/Block/Image'
import Text from '/components/Block/Text'
import TextureBlock from '/components/Block/Texture'

import noop from '/utils/noop'
import getAncestorAttr from '/utils/dom-get-ancestor-attribute-value'

export const CACHE = new Map()

// List of various abstractions/Template constants mirrored as Page state
const TEMPLATE_CONSTANTS = [
  'hasFront',
  'hasBack',
  'hasBackground',
  'hasFrontTextTemplate',
  'hasFrontButtonTemplate',
  'hasFrontIconTemplate',
  'hasBackgroundTextTemplate',
  'hasBackgroundButtonTemplate',
  'hasBackgroundIconTemplate',
  'canUseFrontTextureImage',
  'canUseFrontTextureColor',
  'canUseBackTextureImage',
  'canUseBackTextureColor',
  'canUseBackgroundTextureImage',
  'canUseBackgroundTextureColor',
  'needsXray'
]

export default class PageComponent extends Component {
  beforeRender (props) {
    this.touch = this.touch.bind(this)

    this.handleActive = this.handleActive.bind(this)
    this.handleContext = this.handleContext.bind(this)
    this.handleLock = this.handleLock.bind(this)
    this.handleFolded = this.handleFolded.bind(this)
    this.handleFormat = this.handleFormat.bind(this)
    this.handleTextures = this.handleTextures.bind(this)
    this.handleFontScale = this.handleFontScale.bind(this)

    if (!props.template) throw new Error('The <Page> component requires a template prop')

    this.state = {
      index: derived([Store.app.page, Store.document.pages], () => Array.from(Store.document.pages.current.keys()).indexOf(Store.app.page.current?.props?.template.id)),
      loading: writable(true),
      active: derived(Store.app.page, t => t === this),
      folded: writable(false),

      // Used in controllers/Page to retrieve state, do not populate
      frontTexture: writable(),
      backTexture: writable(),
      backgroundTexture: writable(),
      template: readable(props.template),
      fontScale: derived(props.template.state.fontScale, v => v),
      duration: derived(props.template.state.duration, v => v)
    }

    for (const constant of TEMPLATE_CONSTANTS) this.state[constant] = writable()
  }

  template (props, state) {
    return (
      <div
        class='page'
        store-class-is-active={state.active}
        store-class-is-locked={props.template.state.locked}
        store-class-is-loading={state.loading}
        store-class-is-folded={state.folded}
        event-click={() => Store.app.page.set(this)}
      >
        <Toolbar class='page__toolbar prevent-context-change'>
          <Toolbar class='page__name'>
            <span
              class='page__count'
              store-hidden={derived(Store.document.pages, pages => pages.size <= 1)}
            >
              <span store-text={derived(state.index, i => i + 1)} />
              <span store-text={derived(Store.document.pages, pages => pages.size)} />
            </span>
            <Input
              editOnDblClick
              size='auto'
              type='text'
              class='page__input--name'
              data-title='Cliquer pour sélectionner la page, double-cliquer pour éditer son nom'
              store-value={props.template.state.name}
              placeholder={props.template.props.label}
              event-click={e => Store.app.context.set('page')}
              event-focus={e => Store.app.context.set('page')}
            />
            {Store.env.debug.get() && <span>{props.template.id}</span>}
          </Toolbar>
          <Toolbar>
            <Toolbar store-hidden={derived(Store.document.pages, pages => pages.size <= 1)}>
              <Button
                icon={Icons.moveLeft}
                store-disabled={not(state.index)}
                title='Déplacer ce gabarit vers la gauche'
                event-click={() => Actions.movePage(-1, this)}
              />
              <Button
                icon={Icons.moveRight}
                store-disabled={derived([Store.document.pages, state.index], () => state.index.get() >= Store.document.pages.current.size - 1)}
                title='Déplacer ce gabarit vers la droite'
                event-click={() => Actions.movePage(+1, this)}
              />
            </Toolbar>

            <Toolbar compact>
              <Button
                icon={Icons.lock}
                title='Déverrouiller le gabarit'
                store-hidden={not(props.template.state.locked)}
                event-click={BlockController.exec('focus', () => props.template.state.locked.set(false))}
              />
              <Button
                active
                icon={Icons.unlock}
                title='Verrouiller le gabarit'
                store-hidden={props.template.state.locked}
                event-click={BlockController.exec('focus', () => props.template.state.locked.set(true))}
              />
            </Toolbar>

            <Button
              icon={Icons.delete}
              title='Supprimer le gabarit'
              event-click={e => Actions.confirm(() => Actions.deletePage(this), {
                message: <p>Supprimer le gabarit ? Cette action ne peut être annulée.</p>,
                confirm: {
                  icon: Icons.delete,
                  label: 'supprimer'
                }
              })}
            />

            <Button
              icon={Icons.clone}
              event-click={() => {
                Actions.clonePage(this)
                window.requestAnimationFrame(() => Actions.selectPage(+1))
              }}
              title='Dupliquer ce gabarit'
            />

            <Button
              icon={Icons.addTemplate}
              event-click={() => Actions.insertPage(state.index.get() + 1)}
              title='Ajouter un nouveau gabarit directement après celui-ci'
            />
          </Toolbar>
        </Toolbar>
      </div>
    )
  }

  afterRender (props) {
    // Update current page
    Store.app.page.set(this, true)

    // Directly edit this page if not in animation (grid) mode
    if (!Context.match('timeline', Store.app.context.get())) Store.app.context.set('page')

    Store.app.context.subscribe(this.handleContext)
    Store.document.format.subscribe(this.handleFormat)
    this.state.folded.subscribe(this.handleFolded)
    this.state.active.subscribe(this.handleActive)

    props.template.state.locked.subscribe(this.handleLock)
    props.template.state.textures.subscribe(this.handleTextures)
    props.template.state.fontScale.subscribe(this.handleFontScale)
  }

  async afterMount () {
    // Keep a reference of this <Page> in the Template abstraction
    this.props.template.page = this

    await this.props.template.fetch()

    // Update internal states
    this.refs.template = this.props.template.element
    const proto = Object.getPrototypeOf(this.props.template)
    for (const constant of TEMPLATE_CONSTANTS) {
      if (!Object.prototype.hasOwnProperty.call(proto, constant)) {
        throw new Error(`Template does not have a '${constant}' property`)
      }
      this.state[constant].set(this.props.template[constant])
    }

    this.render(this.refs.template, this.base)

    // Decorate all contenteditable
    for (const element of this.refs.template.querySelectorAll('[contenteditable]')) {
      this.insertText(element, {
        type: element.dataset.type
      }, { insertBefore: element.nextSibling })
    }

    // Attach a Context to each part
    for (const element of this.refs.template.querySelectorAll('[data-part]')) {
      this.render((
        <Context name={`part:${getAncestorAttr(element, 'data-part')}`}>
          {element}
        </Context>
      ), element.parentNode)
    }

    // Decorate all images
    for (const element of this.refs.template.querySelectorAll('img')) {
      const type = element.dataset.type ?? 'image'
      this.insertImage(element, {
        type,
        gallery: element.dataset.gallery,
        ref: this.refArray(`image-${getAncestorAttr(element, 'data-part') ?? 'front'}`),
        sources: Data.defaults[type]?.sources ?? Data.defaults[type]
      }, { insertBefore: element.nextSibling })
    }

    // Decorate foldable elements
    for (const element of this.refs.template.querySelectorAll('[data-folds]')) {
      this.render((
        <Foldable
          ref={this.refArray('foldables')}
          template-slot={this.props.template.createSlot(element)}
          target={element}
          store-folded={this.state.folded}
          event-release={this.touch}
        />
      ), this.refs.template)
    }

    // Handle stored user images and user texts
    for (const [slot, data] of this.props.template.slots) {
      if (slot.startsWith('user[images]')) {
        this.insertImage(undefined, {
          part: (slot.match(/user\[images\]\[(.*)\]\[\d+\]/) ?? [])[1],
          sources: data.sources,
          type: 'icon',
          gallery: 'icons', // TODO store gallery inside slot ? or inside slot name ?
          templateSlot: this.props.template.createSlot(undefined, slot)
        })
      }

      if (slot.startsWith('user[texts]')) {
        this.insertText(undefined, {
          part: slot.match((/user\[texts\]\[(.*)\]\[\d+\]/) ?? [])[1],
          templateSlot: this.props.template.createSlot(undefined, slot)
        })
      }

      if (slot.startsWith('user[buttons]')) {
        this.insertText(undefined, {
          part: slot.match((/user\[buttons\]\[(.*)\]\[\d+\]/) ?? [])[1],
          type: 'button',
          templateSlot: this.props.template.createSlot(undefined, slot)
        })
      }
    }

    this.touch()
    this.reflow()
    this.handleLock()
    this.handleTextures()
    this.handleFontScale()
    this.handleFolded()
    this.state.loading.set(false)
  }

  getBlockTemplate (name, part = null) {
    const blockTemplate = this.base.querySelector(part
      ? `[data-part="${part}"] template[type="${name}"]`
      : `template[type="${name}"]`
    )

    return blockTemplate && {
      xray: blockTemplate.hasAttribute('data-trigger-xray'),
      element: blockTemplate.content.children[0].cloneNode(true),
      parent: blockTemplate.parentNode
    }
  }

  insertText (element, {
    type = 'text',
    part = null,

    ref = this.refArray('blocks'),
    templateSlot = this.props.template.createSlot(element),

    title = 'Double-cliquer pour éditer le texte',
    locked = undefined
  } = {}, target) {
    // If no element given, try inserting a blank text based on template[name=type] found in the Page Template
    if (!element) {
      const blockTemplate = this.getBlockTemplate(type, part)
      if (!blockTemplate) return warn('Ce gabarit ou une partie de ce gabarit ne supporte pas l’ajout de texte personnalisé, certains textes seront ignorés')

      if (blockTemplate.xray && !Store.app.xray.get()) {
        Store.app.xray.set(true)
        warn('Le mode <b>rayons X</b> a été activé pour simplifier la manipulation de l’élement ajouté', null, { duration: 5000 })
      }

      return this.insertText(blockTemplate.element, { ...arguments[1], locked: false }, blockTemplate.parent)
    }

    return this.render((
      <Context name={`object:text:${type}`}>
        <Block
          move={element.dataset.move}
          resize={element.dataset.resize}
          ref={ref}
          template-slot={templateSlot}
          title={title}
          container={element.parentNode}
          event-update={this.touch}
          event-dblclick={BlockController.exec('editText')}
          {...(locked !== undefined ? { locked } : { 'store-locked': this.props.template.state.locked })}
        >
          <Text
            template-slot={templateSlot}
            event-blur={this.touch}
            data-type={type}
          >
            {element}
          </Text>
        </Block>
      </Context>
    ), target)
  }

  insertImage (element, {
    type = 'image',
    gallery = null,
    part = null,

    ref = null,
    templateSlot = this.props.template.createSlot(element),
    sources = {},

    title = 'Double-cliquer pour éditer l’image',
    locked = undefined,
    eventDblclick = noop
  } = {}, target) {
    // If no element given, try inserting a blank image based on template[name='type'] found in the Page Template
    if (!element) {
      const blockTemplate = this.getBlockTemplate(type, part)
      if (!blockTemplate) return warn('Ce gabarit ou une partie de ce gabarit ne supporte pas l’ajout d’image personnalisée, certaines images seront ignorées')

      if (blockTemplate.xray && !Store.app.xray.get()) {
        Store.app.xray.set(true)
        warn('Le mode <b>rayons X</b> a été activé pour simplifier la manipulation de l’élement ajouté', null, { duration: 5000 })
      }

      return this.insertImage(blockTemplate.element, {
        ...arguments[1],
        locked: false,
        gallery: blockTemplate.element.dataset.gallery,
        ref: this.refArray(`image-${getAncestorAttr(blockTemplate.parent, 'data-part') ?? 'front'}`)
      }, blockTemplate.parent)
    }

    return this.render((
      <Context name={`object:image:${type}`}>
        <Block
          move={element.dataset.move}
          resize={element.dataset.resize}
          ref={this.refArray('blocks')}
          template-slot={templateSlot}
          title={title}
          container={element.parentNode}
          event-update={this.touch}
          event-dblclick={gallery ? BlockController.exec('setSources', () => Actions.selectImage(gallery)) : noop}
          {...(locked !== undefined ? { locked } : { 'store-locked': this.props.template.state.locked })}
        >
          <Image
            ref={ref}
            template-slot={templateSlot}
            sources={sources}
            event-update={this.touch}
          >
            {element}
          </Image>
        </Block>
      </Context>
    ), target)
  }

  touch () {
    if (Store.env.debug.get()) this.log('touched.')

    const now = Date.now()
    Store.document.lastTouched.set(now)
    this.props.template.state.lastTouched.set(now)
  }

  reflow () {
    this.refs.template.classList.add('is-resizing')
    for (const block of this.refs.blocks ?? []) block?.reflow()
    for (const foldable of this.refs.foldables ?? []) foldable.reflow()
    window.requestAnimationFrame(() => this.refs?.template.classList.remove('is-resizing'))
  }

  handleActive () {
    if (!this.state.active.get()) return
    this.reflow()
  }

  handleContext () {
    for (const part of this.refs.template.querySelectorAll('[data-part]')) {
      part.classList.toggle('is-active', Context.match(`part:${part.dataset.part}`))
    }
  }

  handleFolded () {
    this.state.hasBack.set(this.state.folded.get())
  }

  handleFormat () {
    this.reflow()
    this.touch()
    Store.app.block.set(null)
  }

  handleLock () {
    if (this.props.template.state.locked.get()) return

    // Extract all <Block>#base from the CSS layout flow so that they can be
    // dragged without impacting their siblings
    for (const block of (this.refs.blocks ?? []).filter(Boolean)
      // Sort by offsetParent index so that extracting an element does not impact
      // subsequent elements in the same flow
      .sort((a, b) => Array.from(b.base.offsetParent.childNodes).indexOf(b.base) - Array.from(a.base.offsetParent.childNodes).indexOf(a.base))
    ) {
      if (block.props.move) block.unlock()
    }

    this.reflow()
  }

  async handleTextures () {
    const textures = this.props.template.state.textures.get()
    for (const part in textures) {
      const texture = textures[part]
      this.base.style.removeProperty(`--template-texture-${part}-color`)
      this.base.style.removeProperty(`--template-texture-${part}-contrast`)
      if (!texture) continue
      await texture.load()

      // Update current page state
      this.state[part + 'Texture'].set(texture)

      // Store as CSS prop for use in templates
      if (texture.type === 'color') {
        this.base.style.setProperty(`--template-texture-${part}-color`, texture.props.value)
        this.base.style.setProperty(`--template-texture-${part}-contrast`, texture.props.contrast)
      }

      // Apply texture to all relevant nodes
      for (const node of [
        ...(this.refs.template?.querySelectorAll(`[data-part="${part}`) ?? []),
        ...(this.refs[`image-${part}`] ?? []),
        ...(part === 'back' ? this.refs.foldables ?? [] : [])
      ]) {
        texture.paint(node, {
          'event-update': this.touch,
          ref: this.refArray('blocks'),
          render: this.render.bind(this)
        })
      }
    }

    this.touch()
  }

  handleFontScale () {
    this.refs.template.style.setProperty('--font-scale', this.props.template.state.fontScale.get())
    for (const block of this.refs.blocks ?? []) {
      if (block instanceof TextureBlock) block.update()
    }
    this.touch()
  }

  beforeDestroy () {
    this.state.folded.unsubscribe(this.handleFolded)
    this.state.active.unsubscribe(this.handleActive)
    this.props.template.state.locked.unsubscribe(this.handleLock)
    this.props.template.state.textures.unsubscribe(this.handleTextures)
    this.props.template.state.fontScale.unsubscribe(this.handleFontScale)
    Store.app.context.unsubscribe(this.handleContext)
    Store.document.format.unsubscribe(this.handleFormat)
  }
}

import Store from '/data/store'
import { derived, writable } from '@tooooools/ui/state'

import Context from '/components/Context'
import Handle from '/components/Handle'

import * as Actions from '/controllers/Actions'

let block
let current

const SIGNALS = new Map()
const COMMANDS = {
  // Helper to exec an arbitrary callback while keeping current Block focused
  focus: async callback => {
    await callback()
    current?.refs?.editor?.chain().focus().run()
  },

  setAnimation: data => {
    block?.state.animation.set(data)
    Store.document.lastTouched.set(Date.now())
    if (!Store.app.preview.get()) Actions.preview({ pip: true })
  },

  setAnimationTiming: v => {
    block?.state.animationTiming.set(v)
    Store.document.lastTouched.set(Date.now())
    if (!Store.app.preview.get()) Actions.preview({ pip: true })
  },

  setSources: async data => {
    const value = typeof data === 'function'
      ? await data()
      : data
    if (value) current?.state.sources.set(value)
  },

  editText: () => {
    if (!current?.refs?.editor) return
    current.state.editable.set(true)
    current.refs.editor.chain().focus().selectAll().run()
  },

  toggleEditText: () => {
    if (!current?.refs?.editor) return
    current.state.editable.toggle()
    current.refs.editor.chain().focus().selectAll().run()
  },

  toggleBold: () => current?.refs?.editor?.chain().focus().toggleBold().run(),
  toggleItalic: () => current?.refs?.editor?.chain().focus().toggleItalic().run(),
  setTextAlign: v => current?.refs?.editor?.chain().focus().setTextAlign(v).run(),
  setFontSize: v => current?.refs?.editor?.chain().focus().setFontSize(v).run(),
  updateFontSize: v => {
    if (!current?.refs?.editor) return
    const fontSize = current.state.fontSize?.get() ?? parseInt(window.getComputedStyle(current.base).getPropertyValue('font-size'))
    current?.refs.editor?.chain().focus().setFontSize(fontSize + v).run()
  },

  delete: () => {
    if (!block) return
    if (!current) return

    current?.props['template-slot']?.delete()

    block.destroy()
    block = null
    current = null
    Store.app.block.set(null)
    Store.app.context.set('page')
    Store.document.lastTouched.set(Date.now())
  }
}

// Deselect current <Block> when changing context
Store.app.context.subscribe(context => {
  if (Context.match('object:*')) return
  Store.app.block.set(null)
})

// Re-bind all signals when block instance changes
Store.app.block.subscribe(instance => {
  // Unbind all signals
  for (const [key, signal] of SIGNALS) {
    signal.set(null)
    const target = (block?.state?.[key] ?? current?.state?.[key])
    if (!target) continue
    target.unsubscribe(signal.handler)
  }

  // Set new current block
  block = instance
  current = block?._collector.components.find(c => !(c instanceof Handle))

  // Bind available signals to the current <Block> or its direct child
  for (const [key, signal] of SIGNALS) {
    const target = (block?.state[key] ?? current?.state[key])
    if (!target) continue
    signal.set(target.get())
    target.subscribe(signal.handler)
  }
})

// Allow targeting a state signal from the current <Block> or its direct child
// If a value is specified, create the signal and return a derivation testing value equality
export function state (key, value = undefined) {
  if (!SIGNALS.has(key)) {
    const value = (block?.state?.[key] ?? current?.state?.[key])?.get()
    const signal = writable(value)
    signal.handler = v => signal.set(v)

    SIGNALS.set(key, signal)
  }

  return value !== undefined
    ? derived(SIGNALS.get(key), v => v === value)
    : SIGNALS.get(key)
}

// Exec a command on current block instance
export function exec (command, value) {
  return async e => {
    // Ensure current block won’t change due to user inputs
    e?.stopPropagation()

    // Exec command
    if (COMMANDS[command]) await COMMANDS[command](value)
    else console.warn(`No command '${command}' found`)
  }
}

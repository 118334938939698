import './Context.scss'
import { Component } from '@tooooools/ui'
import { derived } from '@tooooools/ui/state'

import Store from '/data/store'

let lastContextEvent

export default class ContextComponent extends Component {
  // Test if a given contextName is active in a currentContext
  static match (name, currentContext = Store.app.context.get()) {
    const currentContextParts = currentContext.split(':')
    const parts = name.split(':')

    for (let i = 0; i < parts.length; i++) {
      // Match wildcard
      if (parts[i] === '*') return true
      if (currentContextParts[i] === '*') return true

      // No more to match
      if (!currentContextParts[i]) return true

      // Fail if parts start to differ
      if (currentContextParts[i] !== parts[i]) return false
    }

    return true
  }

  static matchSignal (contextName) {
    return derived(Store.app.context, curr => ContextComponent.match(contextName, curr))
  }

  beforeRender (props) {
    if (!props.name) throw new Error('<Context> must have a `name` property. Use `<Context name=*>` for a wildcard name matching every context')

    this.handleActive = this.handleActive.bind(this)
    this.spy = this.spy.bind(this)

    this.state = {
      active: ContextComponent.matchSignal(props.name)
    }
  }

  afterMount () {
    if (Array.isArray(this.base)) throw new Error('The <Context /> component must have only one child')

    this.state.active.subscribe(this.handleActive)

    this.base.classList.add('context')
    this.base.dataset.context = this.props.name
    this.base.addEventListener('mousedown', this.spy)
    this.base.addEventListener('focusin', this.spy)

    if (Store.env.debug.get()) {
      this.render((
        <div
          class='context-debug'
          data-name={this.props.name}
          store-class-is-active={this.state.active}
        />
      ), this.base)
    }

    this.handleActive()
  }

  handleActive () {
    if (!this.props.autoHide) return
    this.base.style.display = this.state.active.get() ? '' : 'none'
  }

  spy (e) {
    // Prevent bubbling on context change event while allowing bubbling on mousedown event
    if (e === lastContextEvent) return
    lastContextEvent = e

    // Prevent context change when interacting with element with .prevent-context-change
    const onToolbar = !!e.composedPath().find(el => el.classList?.contains('prevent-context-change'))
    if (onToolbar) return

    // Set current context
    Store.app.context.set(this.props.name)
  }

  beforeDestroy () {
    this.state.active.unsubscribe(this.handleActive)
    this.base.removeEventListener('mousedown', this.spy)
    this.base.removeEventListener('focusin', this.spy)
  }
}

import Store from '/data/store'
import { Colors, Formats } from './common'

export const auth = false

export const portal = {
  logo: '/logos/haut-de-gamme/patrimoniale_1c.svg',
  images: [
    '/portal/haut-de-gamme-1.jpg',
    '/portal/haut-de-gamme-2.jpg'
  ]
}

export const api = {
  // arnaudjuracek/svg-to-pdf does not handle clip-path
  pdfMode: 'rasterize'
}

export const uploads = {
  formats: true,
  templates: false,
  logos: false,
  icons: {
    multiple: true,
    accept: 'image/png, image/jpeg, image/svg+xml'
  },
  textures: {
    multiple: true,
    accept: 'image/png, image/jpeg'
  }
}

export const animation = {
  easing: 'expoInOut',
  duration: '2s',
  transition: {
    overlap: '1s',
    interpolations: {
      opacity: { from: 0, to: 1, duration: '1s', easing: 'expoOut' }
    }
  },
  blocks: {
    image: ['fade', 'fall', 'pan', 'pop', 'rise', 'pulse', 'rotate'],
    text: ['fade', 'fall', 'pan', 'pop', 'rise', 'typewriter', 'shift', 'pulse', 'rotate']
  }
}

export const templates = [
  { label: 'gabarit simple', src: '/templates/haut-de-gamme/default/index.html', preview: { src: '/templates/haut-de-gamme/default/preview.png', ratio: 1200 / 600 } },
  { label: 'gabarit double', src: '/templates/haut-de-gamme/double/index.html', preview: { src: '/templates/haut-de-gamme/double/preview.png', ratio: 1200 / 600 } },
  { label: 'gabarit double + marges', src: '/templates/haut-de-gamme/double-margin/index.html', preview: { src: '/templates/haut-de-gamme/double-margin/preview.png', ratio: 1200 / 600 } }
]

export const galleries = {
  logos: [
    { sources: { black: '/logos/haut-de-gamme/patrimoniale_1c.svg', white: '/logos/haut-de-gamme/patrimoniale_white.svg' }, preview: { src: '/logos/haut-de-gamme/patrimoniale_1c.svg' } }
  ],

  icons: (() => {
    // Import *_white icons just so that vite registers them for the build stage
    import.meta.glob('@assets/icons/*_white.svg', { eager: true, as: 'url' })
    return Object.values(import.meta.glob('@assets/icons/*_black.svg', { eager: true, as: 'url' })).map(src => ({
      group: 'pictogrammes',
      sources: { black: src, white: src.replace('_black.svg', '_white.svg'), color: src },
      preview: { src }
    }))
  })(),

  textures: [
    ...(Store.env.debug.get() ? Object.values(import.meta.glob('@assets/debug/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'debug', src, preview: { ratio: 1 } })) : []),
    ...Object.values(import.meta.glob('@assets/haut-de-gamme/*.{png,jpg,jpeg}', { eager: true, as: 'url' })).map(src => ({ group: 'haut de gamme', src, preview: { ratio: 1 } }))
  ]
}

export const colors = [
  Colors.brown,
  Colors.white,
  { ...Colors.gray, logo: 'black' },
  { value: '#E4FDFF', contrast: Colors.brown.value, logo: 'black', label: 'bleu clair pastel (10%)' },
  { value: '#E9FFF5', contrast: Colors.brown.value, logo: 'black', label: 'vert pastel (10%)' },
  { value: '#FFF4FB', contrast: Colors.brown.value, logo: 'black', label: 'rose pastel (10%)' },
  { value: '#FFFEF1', contrast: Colors.brown.value, logo: 'black', label: 'jaune pastel (10%)' },
  { value: '#E4EDFF', contrast: Colors.brown.value, logo: 'black', label: 'bleu foncé pastel (10%)' }
]

export const formats = [
  Formats.debug,
  Formats.linkedin,
  Formats.print
]
  .filter(formats => formats.length > 0)
  .reduce((formats, group) => [...formats, ...group], [])

export const defaults = {
  logo: galleries.logos[0],
  front: colors[1],
  background: galleries.textures[0],
  back: null // No back in haut-de-gamme because no folds
}

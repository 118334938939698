import UUID from '/data/uuid'
import Store from '/data/store'

import * as Api from '/controllers/Api'

const id = UUID.session()
const start = Date.now()

export async function send (filetype = 'unknown') {
  const pages = [/* { template, icons: [], textures: [] } */]
  for (const [, template] of Store.document.pages.get()) {
    const data = template.toJSON()

    const icons = []
    for (const [id, slot] of Object.entries(data.slots)) {
      if (!id.startsWith('user[images]')) continue
      icons.push(slot.sources[slot.source] ?? '<custom>')
    }

    const textures = []
    for (const [part, texture] of Object.entries(data.state.textures)) {
      if (!texture) continue
      textures.push({
        part,
        type: texture.type,
        value: texture.type === 'image'
          ? texture.props.src
          : texture.type === 'color'
            ? texture.props.label
            : null
      })
    }

    pages.push({
      template: template.props.label,
      icons,
      textures
    })
  }

  const timestamp = Date.now()
  return Api.log({
    id,
    timestamp,
    filetype,
    duration: timestamp - start,

    version: Store.env.version.get(),
    endpoint: Store.env.endpoint.get(),

    format: Store.document.format.get(),
    formats: Store.document.formats.get(),

    pages
  })
}

/* global __VERSION__, __RELEASE__ */
import '/index.scss'
import { render } from '@tooooools/ui'

import { debounce } from 'throttle-debounce'
import * as Sentry from '@sentry/browser'

import Store from '/data/store'
import Data from '/data/static'

import * as Api from '/controllers/Api'
import * as Auth from '/controllers/Auth'
import * as Browser from '/controllers/Browser'
import * as Document from '/controllers/Document'
import * as LocalStorage from '/controllers/LocalStorage'
import { log, warn, error } from '/controllers/Toast'
import * as Tour from '/controllers/Tour'

import { Modal } from '@tooooools/ui/components'
import App from '/components/App'
import Portal from '/components/Portal'
import Changelog from '/components/Modal/Changelog'

console.log(`%c${Store.env.version.get()}`, 'font-weight: bold')

window.addEventListener('unhandledrejection', e => {
  error('Une erreur inconnue est survenue.<br>Un nouveau ticket a automatiquement été envoyé aux administrateurs.', e.reason.stack)
})

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: __RELEASE__,
    environment: import.meta.env.MODE,
    integrations: [
      Sentry.replayIntegration({ maskAllText: false, blockAllMedia: false })
    ],
    replaysSessionSampleRate: import.meta.env.MODE === 'production' ? 0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend: async (event, hint) => {
      hint.attachments = [{
        filename: 'document.alm',
        data: JSON.stringify(await Document.data.dump()),
        contentType: 'application/json'
      }]

      event.extra = {
        ...event.extra,
        viewportScale: Store.app.viewportScale.get(),
        context: Store.app.context.get(),
        block: Store.app.block.get()?.base.innerHTML,
        page: Store.app.page.get()?.props.template?.id
      }

      return event
    }
  })
}

;(async () => {
  // Fail on mobile
  if (Browser.isMobile) {
    return render(<div class='fatal'>Navigateur mobile non supporté.</div>)
  }

  // As per client request, redirect / to /alm. Portal is accesible via /portail
  if (window.location.pathname === '/') {
    window.location.href = '/alm'
    return render(<div class='fatal'>Redirection en cours…</div>)
  }

  // Render portal
  if (window.location.pathname === '/portail') {
    return render(<Portal />)
  }

  // Render 404
  if (!Data) {
    return render(<div class='fatal'><p>Aucune configuration trouvée.<br /><a href='/portail'>Retourner au portail</a></p></div>)
  }

  // Render Authentication modal
  if (Data.auth) await Auth.authenticate()

  // Render app
  document.body.classList.add('is-loading')
  Store.document.format.set(Data.formats[0])
  Api.connect()
  render(<App />)
  document.body.classList.remove('is-loading')

  // Warn if unsupported features
  if (!('indexedDB' in window)) {
    warn('La fonctionnalité de restoration de session n’est pas supportée par votre navigateur : votre document ne pourra pas être enregistré automatiquement')
  } else {
    // Restore the last session if any
    const lastSession = await Document.session.check()
    if (lastSession) {
      await Document.session.load()
      if (!Document.data.isEmpty()) log(`Votre session datant du <b>${new Intl.DateTimeFormat('fr-FR', { dateStyle: 'long', timeStyle: 'short' }).format(new Date(lastSession))}</b> a été restaurée`, null, { duration: 10000 })
    }
  }

  // Display the changelog if client has never seen this version
  const previousVersion = LocalStorage.get('version')
  if (previousVersion) {
    if (previousVersion !== __VERSION__) {
      render(
        <Changelog
          previous={previousVersion}
          event-close={() => LocalStorage.set('version', __VERSION__)}
        />)
    }
  } else {
    // Run the tour for new users
    Tour.start({ force: true })
    LocalStorage.set('version', __VERSION__)
  }

  // Display unsupported browser warning
  if (!Browser.isSupported) {
    render(
      <Modal title='Navigateur non supporté'>
        <div class='prose'>
          <p>
            Votre navigateur <b>{Browser.name} {Browser.version}</b> n’est pas officiellement supporté.<br />
            Vous pouvez fermer ce message et utiliser cet outil, mais les performances peuvent être dégradées.
          </p>
          <p>Navigateurs activement supportés&thinsp;:</p>
          <ul>
            {Object.entries(Browser.supported).map(([name, version]) => (
              <li>{name} <b>{version.replace('>=', '≥')}</b></li>
            ))}
          </ul>
        </div>
      </Modal>
    )
  }

  // Store the document to IndexedDB everytime it is modified
  Store.document.lastTouched.subscribe(debounce(100, Document.session.save, { noLeading: true }))

  window.addEventListener('beforeunload', e => {
    if (Document.data.isEmpty()) return
    e.preventDefault()
    e.returnValue = true
  })
})()

import { render } from '@tooooools/ui'

import Auth from '/components/Modal/Auth'

export const recoverLink = import.meta.env.VITE_API_ACCOUNTS_RECOVER_URL

export async function logFromSession () {
  const resp = await fetch(import.meta.env.VITE_API_ACCOUNTS_URL, {
    credentials: 'include'
  })

  return resp.ok
}

export async function logFromCredentials ({ username, password } = {}) {
  const resp = await fetch(import.meta.env.VITE_API_ACCOUNTS_URL, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Basic ${btoa(username + ':' + password).toString('base64')}`
    }
  })

  if (resp.ok) return true

  const json = await resp.json()
  console.error(json.message ?? 'Unknown login error')
  return false
}

export async function authenticate (username, password) {
  if (await logFromSession()) return true
  return new Promise(resolve => render(<Auth event-success={resolve} />))
}

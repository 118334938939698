export const Formats = {
  debug: import.meta.env.MODE === 'test'
    ? [
        { group: 'debug', label: 'template-preview', width: 1200, height: 600, unit: 'px', baseFontScale: 1 },
        { group: 'debug', label: 'square', width: 512, height: 512, unit: 'px', baseFontScale: 0.75 },
        { group: 'debug', label: 'landscape', width: 1024, height: 512, unit: 'px', baseFontScale: 1 },
        { group: 'debug', label: 'portrait', width: 512, height: 1024, unit: 'px', baseFontScale: 1 }
      ]
    : [],
  facebook: [
    { group: 'facebook', label: 'image partagée', width: 1200, height: 630, unit: 'px', baseFontScale: 1 },
    { group: 'facebook', label: 'image partagée carrée', width: 450, height: 450, unit: 'px', baseFontScale: 0.7 },
    { group: 'facebook', label: 'image de couverture', width: 851, height: 351, unit: 'px', baseFontScale: 0.6 },
    { group: 'facebook', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  instagram: [
    { group: 'instagram', label: 'publication carrée', width: 1080, height: 1080, unit: 'px', baseFontScale: 1 },
    { group: 'instagram', label: 'publication paysage', width: 1200, height: 628, unit: 'px', baseFontScale: 1 },
    { group: 'instagram', label: 'publication portrait', width: 1080, height: 1350, unit: 'px', baseFontScale: 1 },
    { group: 'instagram', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  x: [
    { group: 'x', label: 'image partagée', width: 1024, height: 512, unit: 'px', baseFontScale: 0.9 },
    { group: 'x', label: 'image de couverture', width: 1500, height: 500, unit: 'px', baseFontScale: 1 },
    { group: 'x', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  linkedin: [
    { group: 'linkedin', label: 'nouvelle de l’entreprise', width: 1200, height: 628, unit: 'px', baseFontScale: 1 },
    { group: 'linkedin', label: 'image de couverture', width: 1584, height: 396, unit: 'px', baseFontScale: 0.8 },
    { group: 'linkedin', label: 'couverture page vitrine', width: 1264, height: 220, unit: 'px', baseFontScale: 0.5 },
    { group: 'linkedin', label: 'story', width: 1080, height: 1920, unit: 'px', baseFontScale: 1.6 }
  ],
  print: [
    { group: 'impression', label: 'A3 paysage', width: 420, height: 297, unit: 'mm', baseFontScale: 1.3 },
    { group: 'impression', label: 'A3 portrait', width: 297, height: 420, unit: 'mm', baseFontScale: 1.3 },
    { group: 'impression', label: 'A4 paysage', width: 297, height: 210, unit: 'mm', baseFontScale: 0.9 },
    { group: 'impression', label: 'A4 portrait', width: 210, height: 297, unit: 'mm', baseFontScale: 0.9 }
  ]
}

export const Colors = (() => {
  const brown = { value: '#381A0A', contrast: '#FFFFFF', logo: 'white', label: 'brun' }
  const white = { value: '#FFFFFF', contrast: brown.value, logo: 'black', label: 'blanc' }
  const gray = { value: '#F2F2F2', contrast: '#381A0A', logo: 'color', label: 'blanc cassé' }

  const primary = {
    blue: { value: '#00E8FF', contrast: brown.value, logo: 'black', label: 'bleu clair' },
    darkblue: { value: '#0052FF', contrast: white.value, logo: 'white', label: 'bleu foncé' },
    green: { value: '#29FF9B', contrast: brown.value, logo: 'black', label: 'vert' },
    darkgreen: { value: '#04BFAD', contrast: brown.value, logo: 'black', label: 'vert foncé' },
    yellow: { value: '#FFE145', contrast: brown.value, logo: 'black', label: 'jaune' },
    red: { value: '#F6261B', contrast: white.value, logo: 'white', label: 'rouge' },
    pink: { value: '#FF91DE', contrast: brown.value, logo: 'black', label: 'rose clair' },
    darkpink: { value: '#F252AA', contrast: brown.value, logo: 'black', label: 'rose foncé' },
    orange: { value: '#FE7D19', contrast: brown.value, logo: 'black', label: 'orange' },
    purple: { value: '#7F00FF', contrast: white.value, logo: 'white', label: 'violet' }
  }

  const secondary = {
    cyan: { value: '#E6FDF5', contrast: brown.value, logo: 'black', label: 'cyan secondaire' },
    blue: { value: '#E5EEFF', contrast: brown.value, logo: 'black', label: 'bleu secondaire' },
    darkblue: { value: '#99BAFF', contrast: brown.value, logo: 'black', label: 'bleu entre deux' },
    green: { value: '#CDFFEB', contrast: brown.value, logo: 'black', label: 'vert secondaire' },
    darkgreen: { value: '#D9F5F3', contrast: brown.value, logo: 'black', label: 'vert foncé' },
    purple: { value: '#DFC7FF', contrast: brown.value, logo: 'black', label: 'violet secondaire' },
    red: { value: '#FFCCCC', contrast: brown.value, logo: 'black', label: 'rouge secondaire' },
    orange: { value: '#FFD4AC', contrast: brown.value, logo: 'black', label: 'orange secondaire' },
    yellow: { value: '#FFFAD4', contrast: brown.value, logo: 'black', label: 'jaune secondaire' }
  }

  // Define color harmonies, used in some very specific templates
  for (const [color, harmonies] of new Map([
    [brown, [gray, primary.blue, primary.darkblue]],
    [gray, [brown, primary.blue, primary.darkblue]],
    [primary.blue, [primary.yellow, primary.darkpink, primary.purple]],
    [primary.yellow, [primary.darkpink, primary.purple, primary.darkgreen]],
    [primary.darkpink, [primary.purple, primary.darkgreen, primary.yellow]],
    [primary.purple, [primary.darkgreen, primary.yellow, primary.darkpink]],
    [primary.darkgreen, [primary.yellow, primary.darkpink, primary.purple]]
  ])) {
    // Export harmonies as CSS props
    color.css ??= {}
    for (let index = 0; index < harmonies.length; index++) {
      const { value, contrast } = harmonies[index]
      color.css[`--color-harmony-${index + 1}`] = value
      color.css[`--color-harmony-${index + 1}-contrast`] = contrast
    }
  }

  // Attach secondaries to primaries
  for (const [color, faded] of new Map([
    [brown, primary.blue],
    [primary.blue, brown],
    [primary.darkblue, secondary.darkblue],
    [primary.green, secondary.green],
    [primary.darkgreen, secondary.darkgreen],
    [primary.yellow, secondary.yellow],
    [primary.red, secondary.red],
    [primary.pink, primary.darkpink],
    [primary.darkpink, primary.pink],
    [primary.orange, secondary.orange],
    [primary.purple, secondary.darkblue]
  ])) {
    // Export secondaries as CSS props
    color.css ??= {}
    color.css['--color-secondary'] = faded.value
    color.css['--color-secondary-contrast'] = faded.contrast
  }

  return {
    brown,
    white,
    gray,
    primary,
    secondary
  }
})()

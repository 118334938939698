/* global DOMParser */
import HtmlToSvg from '@tooooools/html-to-svg'
import { ensure, writable } from '@tooooools/ui/state'

import Data from '/data/static'
import UUID from '/data/uuid'

import Texture from '/abstractions/Texture'

const parser = new DOMParser()
const CACHE = new Map()
const RENDERER = new HtmlToSvg({
  debug: new URLSearchParams(window.location.search).get('debug') === 'svg',
  ignore: 'template, .no-export',
  fonts: [
    { family: 'Relative, sans-serif', url: '/fonts/relative-book.otf' },
    { family: 'Relative, sans-serif', url: '/fonts/relative-book-italic.otf', style: 'italic' },
    { family: 'Relative, sans-serif', url: '/fonts/relative-bold.otf', weight: '700' },
    { family: 'Relative, sans-serif', url: '/fonts/relative-bold-italic.otf', weight: '700', style: 'italic' }
  ]
})

export default class Template {
  constructor ({
    id = UUID.page(),
    props = {}, // As defined in /data/static
    slots = {}, // Keep a reference to a previous content
    raw = null, // Pass a HTML as string to skip fetching
    state = {} // Pass an object to set the Template state
  } = {}) {
    this.id = id
    this.props = Object.freeze(props)
    this.raw = raw

    // Ensure slots is always a Map
    this.slots = slots instanceof Map ? slots : new Map(Object.entries(slots))

    // Manage all persistent data on a <Page>
    this.state = {
      name: writable(''),
      lastTouched: writable(Date.now()),
      locked: writable(true),
      fontScale: writable(1),
      duration: writable(2),
      textures: writable({
        front: Data.defaults.front && new Texture({ props: Data.defaults.front }),
        back: Data.defaults.back && new Texture({ props: Data.defaults.back }),
        background: Data.defaults.background && new Texture({ props: Data.defaults.background })
      })
    }

    // Keep a reference of the linked <Page> instance
    this.page = null

    // Import state from constructor
    for (const key in state ?? {}) {
      this.state[key] = ensure(writable)(state[key])
    }

    // Ensure state.textures is correctly instanciated as Texture
    this.state.textures.update(textures => {
      for (const part in textures) {
        if (textures[part] instanceof Texture) continue
        if (!textures[part]) continue
        textures[part] = new Texture(textures[part])
      }
    })

    // Prepare the template HTMLElement
    this.element = document.createElement('section')
    this.element.id = this.id
    this.element.classList.add('template')
  }

  // Template constants, used in components/Page.jsx as signal
  get hasFront () { return Boolean(this.element.querySelector('[data-part="front"]')) }
  get hasBack () { return Boolean(this.element.querySelector('[data-part="front"][data-folds]')) }
  get hasBackground () { return Boolean(this.element.querySelector('[data-part="background"]')) }
  get hasFrontTextTemplate () { return Boolean(this.element.querySelector('[data-part="front"] template[type="text"]')) }
  get hasFrontButtonTemplate () { return Boolean(this.element.querySelector('[data-part="front"] template[type="button"]')) }
  get hasFrontIconTemplate () { return Boolean(this.element.querySelector('[data-part="front"] template[type="icon"]')) }
  get hasBackgroundTextTemplate () { return Boolean(this.element.querySelector('[data-part="background"] template[type="text"]')) }
  get hasBackgroundButtonTemplate () { return Boolean(this.element.querySelector('[data-part="background"] template[type="button"]')) }
  get hasBackgroundIconTemplate () { return Boolean(this.element.querySelector('[data-part="background"] template[type="icon"]')) }
  get canUseFrontTextureImage () { return Boolean(this.element.querySelector('[data-part="front"]:not([data-prevent-texture-image])')) }
  get canUseFrontTextureColor () { return Boolean(this.element.querySelector('[data-part="front"]:not([data-prevent-texture-color])')) }
  get canUseBackTextureImage () { return Boolean(this.element.querySelector('[data-folds]:not([data-prevent-texture-image])')) }
  get canUseBackTextureColor () { return Boolean(this.element.querySelector('[data-folds]:not([data-prevent-texture-color])')) }
  get canUseBackgroundTextureImage () { return Boolean(this.element.querySelector('[data-part="background"]:not([data-prevent-texture-image])')) }
  get canUseBackgroundTextureColor () { return Boolean(this.element.querySelector('[data-part="background"]:not([data-prevent-texture-color])')) }
  get needsXray () { return Boolean(this.element.querySelector('[data-trigger-xray]')) }

  async fetch () {
    if (!this.raw) {
      if (!CACHE.has(this.props.src)) {
        const resp = await fetch(this.props.src)
        const html = await resp.text()
        CACHE.set(this.props.src, html)
      }

      this.raw = CACHE.get(this.props.src)
    }

    this.element.innerHTML = this.raw
      .replace(/:scope/g, '#' + this.id)
      .trim()
  }

  // Create an entry for a HTMLElement in the Template.slots Map, allowing data
  // storage at the discretion of a specific component via the template-slot prop
  createSlot (element, slot = element?.dataset.slot) {
    // Dynamically increment an ID if the syntax is `data-slot=namespace[]`
    let [, namespace, id] = /(.*)\[(.*)\]$/.exec(slot) ?? []
    if (!id) {
      if (!this.slots.indexes) this.slots.indexes = {}
      id = this.slots.indexes[namespace] = (this.slots.indexes[namespace] ?? -1) + 1
      slot = `${namespace}[${id}]`
      if (element) element.dataset.slot = slot
      return this.createSlot(element, slot)
    }

    return {
      get: () => this.slots.get(slot),
      set: data => this.slots.set(slot, data),
      update: callback => this.slots.set(slot, callback(this.slots.get(slot))),
      delete: () => {
        this.slots.delete(slot)

        // Remove the element from the raw template source
        const raw = parser.parseFromString(this.raw, 'text/html')
        raw.querySelector(`[data-slot='${slot}']`)?.remove()
        this.raw = raw.body.innerHTML
      }
    }
  }

  toHTML () {
    return this.element.outerHTML
  }

  async toSVG (options, callback) {
    // Avoid concurent renderings
    if (this.element.matches('.is-rendering')) return this.lastRender

    this.element.classList.add('is-rendering')
    await RENDERER.preload()
    await new Promise(resolve => window.setTimeout(resolve, 1))
    // TODO[next] clone instead (avoid flashing when transform:none!important is applied)
    const svg = await RENDERER.render(this.element, options, callback)

    // Remove unneeded elements
    for (const el of svg.querySelectorAll('[visibility="hidden"]')) el.remove()

    this.element.classList.remove('is-rendering')
    this.lastRender = svg

    return svg
  }

  toJSON () {
    return {
      id: this.id,
      props: this.props,
      slots: Object.fromEntries(this.slots),
      raw: this.raw,
      state: Object.entries(this.state).reduce((state, [key, signal]) => {
        state[key] = signal.get()
        return state
      }, {})
    }
  }
}

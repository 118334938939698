import './Image.scss'
import { Component } from '@tooooools/ui'
import Store from '/data/store'
import { ensure, readable, writable } from '@tooooools/ui/state'

import classnames from 'classnames'

import Block from '/components/Block'

import noop from '/utils/noop'
import slugify from '/utils/string-slugify'

export default class ImageBlockComponent extends Component {
  beforeRender (props) {
    this.update = this.update.bind(this)

    this.state = {
      type: readable('image'),
      source: ensure(writable)(props['store-source'] ?? props.source), // 'source1' (if nullish, fallback to first key of state.sources)
      sources: ensure(writable)(props['store-sources'] ?? props.sources) // { source1: 'src', source2: 'src', … }
    }
  }

  template (props) {
    return (
      <div
        title={props.title}
        class={classnames('image', props.class)}
        event-dblclick={props['event-dblclick']}
      >
        {props.children[0] ?? <img />}
      </div>
    )
  }

  get width () { return this.refs?.image.width }
  get height () { return this.refs?.image.height }
  get naturalWidth () { return this.refs?.image.naturalWidth }
  get naturalHeight () { return this.refs?.image.naturalHeight }
  get ratio () { return this.refs?.image ? this.naturalWidth / this.naturalHeight : 1 }

  afterMount () {
    this.ref('image')(this?.base.querySelector('img'))
    if (!this.refs.image) return

    // Transfer <img> margin to <Image />
    this.base.style.margin = window.getComputedStyle(this.refs.image).getPropertyValue('margin')
    this.refs.image.style.margin = 0
    this.refs.image.setAttribute('draggable', 'false')

    // Prevent animation
    if (this.refs.image.hasAttribute('data-prevent-animations')) {
      if (this._parent instanceof Block) this._parent.state.animatable.set(false)
    }

    const slot = this.props['template-slot']?.get()
    if (slot) {
      this.state.source.current = slot.source

      // Extract new objectUrl from assets map if the sources object is from a user uploaded asset
      if (slot.sources.assets) {
        slot.sources.src = (() => {
          if (!Store.document.assets.current[slot.sources.assets]) return
          for (const [filename, src] of Store.document.assets.current[slot.sources.assets]) {
            if (filename === slot.sources.filename) return src
          }
        })()
      }

      this.state.sources.current = slot.sources
    }

    this.state.source.subscribe(this.update)
    this.state.sources.subscribe(this.update)
    this.update()
  }

  update () {
    const sources = this.state.sources.get()
    if (!sources) return

    const source = this.state.source.get() ?? Object.keys(sources)[0]

    if (this.props['template-slot']) {
      this.props['template-slot'].update(data => ({ ...data, source, sources }))
    }

    this.refs.image.src = sources[source] ?? sources.src // Handle user uploaded assets
    this.refs.image.setAttribute('data-filename', slugify(sources.filename ??
      this.refs.image.src
        .replace(window.location.origin, '')
        .replace(/^(.*\/public)/, '')
    ))

    if (this.refs.image.complete) (this.props['event-load'] ?? noop)(null, this)
    else this.refs.image.onload = () => (this.props['event-load'] ?? noop)(null, this)

    if (this._parent instanceof Block) this._parent.reflow()
    ;(this.props['event-update'] ?? noop)(null, this)
  }

  beforeDestroy () {
    if (this.refs.image) this.refs.image.onload = noop
  }
}

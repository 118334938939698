import { render } from '@tooooools/ui'
import { derived } from '@tooooools/ui/state'

import hash from 'object-hash'
import { arrayMoveImmutable } from 'array-move'
import { debounce } from 'throttle-debounce'
import { clamp } from 'missing-math'

import Data from '/data/static'
import Store from '/data/store'

import Template from '/abstractions/Template'

import * as Animation from '/controllers/Animation'
import * as Block from '/controllers/Block'
import * as Document from '/controllers/Document'
import * as Page from '/controllers/Page'

import Confirm from '/components/Modal/Confirm'
import Export from '/components/Modal/Export'
import Preview from '/components/Modal/Preview'
import Select from '/components/Modal/Select'

/**
 * Render a confirmation <Modal> before executing the given action
 * Return a Promise resolving once the action is done (or cancelled)
 */
export async function confirm (action, props = {}) {
  const confirmed = await new Promise(resolve => {
    render(
      <Confirm event-close={resolve} event-input={resolve} {...props}>
        {(typeof props.message === 'string'
          ? <div innerHTML={props.message} />
          : props.message
        )}
      </Confirm>
    )
  })

  if (confirmed) return action()
}

/**
 * [exportDocument description]
 */
export function exportDocument () {
  return new Promise(resolve => {
    render(<Export event-close={resolve} />)
  })
}

/**
 * Create a <Backdrop> with the current <Page> preview or the document animation
 */
export async function preview ({ pip = false } = {}) {
  const { svg, tick, timeline } = await Animation.preview()
  Store.raf.frameCount.subscribe(tick)

  Store.app.preview.update(instance => {
    if (instance) {
      instance.destroy()
      tick(Store.raf.frameCount.get())
    } else if (Document.isAnimated.get()) {
      Store.raf.frameCount.set(0)
      Store.raf.running.set(true)
    } else {
      Store.raf.frameCount.set(timeline.length - 1)
      Store.raf.running.set(false)
    }

    const component = render(
      <Preview
        pip={pip}
        length={timeline.length}
        event-close={() => {
          Store.raf.frameCount.unsubscribe(tick)
          Store.document.lastTouched.unsubscribe(update)
          Store.app.preview.set(null)
        }}
      >
        {svg}
      </Preview>
    ).components[0]

    const update = debounce(200, () => preview({ pip: component?.state?.pip.get() ?? pip }))
    Store.document.lastTouched.subscribe(update)

    return component
  }, true)
}

export async function destroyPreview () {
  Store.app.preview.update(instance => {
    instance?.destroy()
    return null
  })
}

/**
 * Open a <Select> with all asset specified by gallery and defined in /data/static
 * If no gallery is found, fallback to user uploadable gallery
 * @return {Promise} The selected image sources if any
 */
export function selectImage (galleryName, part) {
  return new Promise(resolve => {
    const gallery = Data.galleries[galleryName]
    const source = hash(Block.state('sources').current ?? Page.state(part + 'Texture').current?.props ?? {})

    render(
      <Select
        data-name={gallery ? galleryName : 'upload'}
        assets={gallery ? galleryName : 'upload'}
        upload={gallery ? Data.uploads[galleryName] : true}
        title={gallery ? 'Sélectionner une image' : 'Ajouter une image'}
        value={gallery?.find(image => hash(image.sources ?? image) === source)}
        event-select={data => resolve(data.sources ?? data)} // Either data.sources from static assets, or whole data if user uploaded asset
        event-close={resolve}
      >
        {gallery}
      </Select>
    )
  })
}

/**
 * Open a <Select> with all templates defined in /data/static
 * @return {Promise} The selected Template data if any
 */
export async function selectTemplate () {
  return new Promise(resolve => {
    render(
      <Select
        title='Sélectionner un gabarit'
        class='select-modal--templates'
        store-value={derived(Page.state('template'), t => t?.props)}
        event-select={resolve}
        event-close={resolve}
      >
        {Data.templates}
      </Select>
    )
  })
}

/**
 * Instanciate and add a Template to Store.document.pages
 * If no data is given, open a <Select> with all templates defined in /data/static
 */
export async function addPage ({ data = undefined } = {}) {
  return insertPage(undefined, data)
}

/**
 * Instanciate and insert a Template to Store.document.pages at specified index.
 * If no data is given, open a <Select> with all templates defined in /data/static
 */
export async function insertPage (index = Store.document.pages.current.size, data) {
  const previous = Store.app.page.get()

  // Set current template to null so that selectTemplate has no selection and
  // wait for state propagation before continuing
  Store.app.page.set(null)

  data = data ?? { props: await selectTemplate() }
  if (!data?.props) {
    Store.app.page.set(previous)
    return
  }

  const template = new Template(data)

  // Replace the document.pages Map (Map order is based on insertion order)
  Store.document.pages.update(pages => {
    const arr = Array.from(pages.values())
    arr.splice(index, 0, template)

    return new Map(
      // Create a [key, value] array for Map instanciation
      arr.map(template => [template.id, template])
    )
  }, true)

  Store.document.lastTouched.set(Date.now())
}

/**
 * Select a given <Page> instance inside Store.document.pages
 * If no instance is given, use the current <Page> instance
 */
export function selectPage (direction = +1, instance = undefined) {
  instance = instance ?? Store.app.page.get()

  const id = instance?.props.template.id
  if (!id) return

  Store.app.page.update(page => {
    const pages = Store.document.pages.get()
    const keys = Array.from(pages.keys())
    const index = keys.indexOf(id)

    return (Array.from(pages.values())[index + direction] ?? page).page
  }, true)
}

/**
 * Move a given <Page> instance inside Store.document.pages
 * If no instance is given, use the current <Page> instance
 */
export function movePage (direction = +1, instance = undefined) {
  instance = instance ?? Store.app.page.get()

  const id = instance?.props.template.id
  if (!id) return

  // Replace the templates Map (Map order is based on insertion order)
  Store.document.pages.update(pages => {
    const arr = Array.from(pages.keys())
    const index = arr.indexOf(id)

    return new Map(
      // Move instance in the arr
      arrayMoveImmutable(Array.from(pages.values()), index, clamp(index + direction, 0, arr.length))
        // Create a [key, value] array for Map instanciation
        .map(template => [template.id, template])
    )
  }, true)

  Store.document.lastTouched.set(Date.now())
}

/**
 * Create a new <Page> directly after the given instance with the same Template data.
 * If no instance if given, use the current <Page> instance
 */
export function clonePage (instance = Store.app.page.get()) {
  if (!instance) return
  const data = instance.props.template.toJSON()
  const index = Array.from(Store.document.pages.get().keys()).indexOf(data.id)

  return insertPage(index + 1, new Template({ ...data, id: undefined }))
}

/**
 * Remove a given Template instance from Store.document.pages.
 * If no instance is given, use the current <Page> instance
 */
export function deletePage (instance = undefined) {
  instance = instance ?? Store.app.page.get()
  if (!instance) return

  const id = instance?.props.template.id
  if (!id) return

  // Set the new current <Page> to its previous sibling
  Store.app.page.update(page => {
    if (page !== instance) return page

    const pages = Store.document.pages.get()
    const index = Array.from(pages.keys()).indexOf(id)

    const template = Array.from(pages.values())[index > 0 ? index - 1 : 1]
    return template?.page
  })

  // Ensure instance does not persist in Store
  Store.document.pages.update(pages => {
    pages.delete(id)
    return pages
  }, true)

  Store.document.lastTouched.set(Date.now())
}

import './Auth.scss'
import { Component } from '@tooooools/ui'
import { derived, not, writable } from '@tooooools/ui/state'

import * as Icons from '/data/icons'
import * as Auth from '/controllers/Auth'

import noop from '/utils/noop'

import {
  Button,
  Modal,
  Toolbar,
  Input
} from '@tooooools/ui/components'

export default class ConfirmModalComponent extends Component {
  beforeRender () {
    this.handleSubmit = this.handleSubmit.bind(this)

    this.state = {
      invalid: writable(false),
      username: writable(null),
      password: writable(null)
    }
  }

  template (props, state) {
    return (
      <Modal
        locked
        title='Authentification requise'
        class='auth-modal'
      >
        <div class='auth-modal__content'>
          <form
            store-class-is-invalid={state.invalid}
            event-submit={this.handleSubmit}
          >
            <Input
              type='text'
              store-value={state.username}
              icon={Icons.user}
              placeholder='E-mail'
              name='email'
              autofocus
              autocomplete='email'
            />

            <Input
              type='password'
              store-value={state.password}
              icon={Icons.lock}
              class='input-password'
              placeholder='Mot de passe'
            />

            <div
              class='auth-modal__error'
              store-hidden={not(state.invalid)}
            >
              L’utilisateur n’existe pas ou le mot de passe est incorrect.
            </div>

            <Toolbar class='auth-modal__toolbar'>
              <a
                href={Auth.recoverLink}
                event-click={this.handleRecover}
                text='Mot de passe oublié'
                target='_blank'
                rel='noreferrer'
              />

              <Button
                type='submit'
                icon={Icons.ok}
                label='Se connecter'
                store-disabled={derived([state.username, state.password], ([a, b]) => !(a?.length && b?.length))}
                event-click={this.handleSubmit}
              />
            </Toolbar>
          </form>

        </div>

      </Modal>
    )
  }

  async handleSubmit (e) {
    e.preventDefault()

    const success = await Auth.logFromCredentials({
      username: this.state.username.get(),
      password: this.state.password.get()
    })

    if (success) {
      ;(this.props['event-success'] ?? noop)(true, this)
      this.destroy()
    } else {
      this.state.invalid.set(true)
    }
  }
}

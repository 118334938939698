/* global Element */

import { h, render } from '@tooooools/ui'
import { ensure, writable } from '@tooooools/ui/state'
import { Convert } from '@tooooools/utils'

import Store from '/data/store'

import Corner from '/abstractions/Corner'

import Foldable from '/components/Foldable'
import Image from '/components/Block/Image'
import TextureBlock from '/components/Block/Texture'

import slugify from '/utils/string-slugify'

/**
 * Represents either a color or an image, as defined in /data/static
 */

const REFS = new Map()

// NOTE Eventually this should be defined in /data/static
const CONTRASTS = { black: '#381A0A', white: '#FFFFFF' }
const TEXTURE_SNAP_THRESHOLD = 5 // px

export const CACHE = new Map()
export default class Texture {
  // Generate an empty placeholder Texture
  static get empty () {
    return new Texture({ value: CONTRASTS.black, contrast: CONTRASTS.white })
  }

  constructor ({
    props = {}, // As defined in /data/static
    state = {} // Pass an object to set the Texture state
  } = {}) {
    this.props = props
    this.userWantsDark = true

    this.state = {
      offset: writable([0, 0]),
      scale: writable(1),
      mirror: writable([1, 1]), // TODO
      contrast: writable(props.contrast === CONTRASTS.white ? 'white' : 'black')
    }

    // Import state from constructor
    for (const key in state ?? {}) {
      const initial = this.state[key].initial
      this.state[key] = ensure(writable)(state[key])
      this.state[key].initial = initial
    }

    // Prepare image
    if (this.type === 'image') {
      this.image = new window.Image()
      this.image.src = props.assets
        ? Store.document.assets.get()[props.assets]?.get(props.filename)
        : props.src
    }
  }

  get loaded () { return this.image?.complete ?? false }
  get empty () { return !this.props }
  get type () {
    if (this.empty) return null
    if (this.props.src ?? this.props.assets) return 'image'
    if (this.props.value) return 'color'
  }

  // Load the Texture image, and cache it as objectURL to avoid texture flashing
  async load () {
    if (this.type !== 'image') return

    if (!CACHE.has(this.image.src)) {
      const objectUrl = await Convert.image(this.image).toObjectURL()
      CACHE.set(this.image.src, objectUrl)
    }

    return new Promise(resolve => {
      this.image.onload = resolve
      this.image.filename = slugify(this.props.src.replace(/^(.*\/public)/, ''))
      this.image.src = CACHE.get(this.image.src)
    })
  }

  // Apply Texture to a mixed types node
  paint (node, ...args) {
    if (this.empty) return
    if (node instanceof Element) {
      if (node.tagName === 'CANVAS') return this.applyToCanvas(node, ...args)
      else return this.applyToDOMElement(node, ...args)
    }
    if (node instanceof Image) return this.applyToImageComponent(node, ...args)
    if (node instanceof Foldable) return this.applyToFoldableComponent(node, ...args)
    if (node instanceof Corner) return this.applyToCorner(node, ...args)
  }

  applyToDOMElement (element, props = {}) {
    element.dataset.textureType = this.type
    delete element.dataset.textureColor

    if (this.type === 'color') {
      element.dataset.textureColor = this.props.value
      element.style.setProperty('--background', this.props.value)
      element.style.setProperty('--color', this.props.contrast)

      // Inject arbitrary CSS prop from Data.colors[<color.css>]
      for (const prop in this.props.css) {
        element.style.setProperty(prop, this.props.css[prop])
      }

      // Remove potential already applied <TextureBlock>
      if (REFS.has(element)) {
        REFS.get(element).destroy()
        REFS.delete(element)
      }
    }

    if (this.type === 'image') {
      element.style.removeProperty('--background')
      element.style.setProperty('--color', CONTRASTS[this.state.contrast.get()])

      // Destroy already applied <TextureBlock>
      const ref = REFS.get(element)
      if (ref) {
        ref.destroy()
        REFS.delete(element)
      }

      // Render <TextureBlock>
      ;(props.render ?? render)(h(TextureBlock, {
        ...props,
        pan: true,
        zoom: true,
        texture: this,
        ref: c => {
          REFS.set(element, c)
          if (props.ref) props.ref(c)
        }
      }), element)
    }
  }

  applyToImageComponent (component) {
    component.state.source.set(this.props.logo ?? this.state.contrast.get())
  }

  applyToFoldableComponent (component) {
    component.refs.foldable?.forEach(corner => this.applyToCorner(corner))
    component.update()
  }

  applyToCorner (corner) {
    corner.update({ texture: this })
  }

  applyToCanvas (canvas, {
    x = 0,
    y = 0,
    destinationWidth = this.image.naturalWidth,
    destinationHeight = this.image.naturalHeight,
    scale = 1
  } = {}) {
    const context = canvas.getContext('2d')

    canvas.width = context.width = destinationWidth
    canvas.height = context.height = destinationHeight

    const { naturalWidth, naturalHeight } = this.image

    // Crop using a cover algorithm
    const ratio = naturalWidth / naturalHeight
    const containerRatio = destinationWidth / destinationHeight
    const sourceWidth = ratio > containerRatio
      ? naturalHeight * containerRatio
      : naturalWidth
    const sourceHeight = ratio < containerRatio
      ? naturalWidth / containerRatio
      : naturalHeight

    // Clamp scale to ensure full cover
    scale = Math.max(scale, sourceWidth / naturalWidth, sourceHeight / naturalHeight)

    // Find crop [x, y]
    const pixelSize = Math.max(sourceWidth / destinationWidth, sourceHeight / destinationHeight)

    // WIP replace hard constrains by snapped constrains
    let sx = x / scale
    let sy = y / scale
    const xmin = 0
    const ymin = 0
    const xmax = naturalWidth / pixelSize - destinationWidth / scale
    const ymax = naturalHeight / pixelSize - destinationHeight / scale
    if (Math.abs(sx - xmin) < TEXTURE_SNAP_THRESHOLD) sx = xmin
    if (Math.abs(sx - xmax) < TEXTURE_SNAP_THRESHOLD) sx = xmax
    if (Math.abs(sy - ymin) < TEXTURE_SNAP_THRESHOLD) sy = ymin
    if (Math.abs(sy - ymax) < TEXTURE_SNAP_THRESHOLD) sy = ymax

    // Render cropped image
    context.drawImage(
      this.image,
      sx * pixelSize,
      sy * pixelSize,
      sourceWidth / scale,
      sourceHeight / scale,
      0,
      0,
      destinationWidth,
      destinationHeight
    )

    // Return clamped inputs
    return {
      scale,
      offset: [sx * scale, sy * scale]
    }
  }

  toJSON () {
    return {
      props: this.props,
      state: Object.entries(this.state).reduce((state, [key, signal]) => {
        state[key] = signal.get()
        return state
      }, {})
    }
  }
}

/**
 * Singleton defining all possible configurations, and exporting only the one
 * matching the current location.pathname

 * IMPORTANT: All data below are deep-frozen, as they should always be immutable
 * Mutable datas are handled by data/store
 */

import Store from '/data/store'
import deepFreeze from '/utils/object-deep-freeze'

export const Routes = new Map([
  ['/alm', import.meta.glob('./instances/alm.js', { eager: true })],
  ['/haut-de-gamme', import.meta.glob('./instances/haut-de-gamme.js', { eager: true })],
  ['/marque-employeur', import.meta.glob('./instances/marque-employeur.js', { eager: true })]
].map(([endpoint, data]) => ([endpoint, Object.values(data)[0]])).map(([endpoint, data]) => ([
  endpoint,
  deepFreeze([
    'auth',
    'portal',
    'api',
    'uploads',
    'animation',
    'templates',
    'galleries',
    'colors',
    'schemes',
    'formats',
    'defaults'
  ].reduce((m, key) => {
    m[key] = data[key]
    return m
  }, {}))
])))

// Export the correct configuration based on current route
export default (() => {
  const endpoint = Store.env.endpoint.get()
  if (Routes.has(endpoint)) {
    window.__data = Routes.get(endpoint)
    return window.__data
  }
})()

import './Block.scss'
import { Component } from '@tooooools/ui'
import { ensure, derived, writable } from '@tooooools/ui/state'

import classnames from 'classnames'

import Store from '/data/store'
import * as Actions from '/controllers/Actions'

import Handle from '/components/Handle'

import getScaledBoundingClientRect from '/utils/dom-get-scaled-bounding-client-rect'
import noop from '/utils/noop'

export default class BlockComponent extends Component {
  beforeRender (props) {
    this.register = this.register.bind(this)
    this.reflow = this.reflow.bind(this)
    this.handleMove = this.handleMove.bind(this)
    this.handleRelease = this.handleRelease.bind(this)
    this.handleAnimation = this.handleAnimation.bind(this)

    this.state = {
      // As defined in CSS: 'horizontal', 'vertical', 'both' or 'none'|nullish
      move: ensure(writable)(props['store-move'] ?? props.move),
      resize: ensure(writable)(props['store-resize'] ?? props.resize),

      animation: writable(null),
      animationTiming: writable([0, 1]),
      animatable: writable(true),

      active: writable(false),
      frozen: writable(false), // Temporarily freeze all Block related interactions
      selected: derived(Store.app.block, current => current === this), // Automatically unselected on Store.app.context change via /controllers/Block
      locked: ensure(writable)(props['store-locked'] ?? props.locked),

      alreadyUnlocked: writable(false)
    }
  }

  get child () {
    return this?._collector.components.find(c => !(c instanceof Handle))
  }

  template (props, state) {
    return (
      <div
        title={props.title}
        class={classnames('block', props.class)}
        store-data-move={state.move}
        store-data-resize={state.resize}
        store-class-is-selected={state.selected}
        store-class-is-active={state.active}
        store-class-is-frozen={state.frozen}
        store-class-is-locked={state.locked}
        store-class-has-animation={state.animation}
        event-click={this.register}
        event-focusin={this.register}
        event-dblclick={props['event-dblclick']}
      >
        <div class='block__frame'>
          <div
            innerText='▶'
            class='btn--play no-export'
            event-click={e => Actions.preview({ pip: true })}
            title='Cliquer pour prévisualiser l’animation'
          />
          <Handle
            class='main'
            grid={props.grid}
            event-move={this.handleMove}
            event-release={this.handleRelease}
          />
          {['N', 'E', 'S', 'W', 'NW', 'NE', 'SE', 'SW'].map(direction => (
            <Handle
              class={direction.split('')}
              grid={props.grid}
              event-move={this.handleResize(direction)}
              event-release={this.handleRelease}
              event-dblclick={e => {
                e.stopPropagation()
                this.handleResetResize(direction)(e)
              }}
            />
          ))}
        </div>

        {props.children}
      </div>
    )
  }

  afterMount () {
    if (!this.state.locked.get()) this.unlock()

    // Get the style from the template-slot and apply if any
    const slot = this.props['template-slot']?.get()
    if (slot?.style) {
      this.base.setAttribute('style', slot.style)

      const computed = window.getComputedStyle(this.base)
      this.base.dataset.x = parseFloat(computed.getPropertyValue('--block-x') || 0)
      this.base.dataset.y = parseFloat(computed.getPropertyValue('--block-y') || 0)
    }

    // Get animation from template-slot and apply if any
    if (slot?.animation) {
      this.state.animation.set(slot.animation.value)
      this.state.animationTiming.set(slot.animation.timing ?? [0, 1])
    }

    // Store animation state to template-slot
    this.state.animation.subscribe(this.handleAnimation)
    this.state.animationTiming.subscribe(this.handleAnimation)
    this.handleAnimation()
  }

  register () {
    Store.app.block.set(this)
  }

  reflow ({ again = true } = {}) {
    if (!this.mounted) return
    if (this.state.locked.get()) return
    if (this.state.frozen.get()) return
    if (!this.props.container) return

    let reflowAgain = false

    const scale = Store.app.viewportScale.get()
    const padding = (this.props.padding ?? 0) * scale
    const { top, left, bottom, right, width, height } = getScaledBoundingClientRect(this.base, scale)

    const container = getScaledBoundingClientRect(this.props.container, scale)

    // Ensure position is in container bounds
    if (top < container.top + padding) this.handleMove([0, (container.top + padding) - top])
    if (right > container.right - padding) this.handleMove([(container.right - padding) - right, 0])
    if (bottom > container.bottom - padding) this.handleMove([0, (container.bottom - padding) - bottom])
    if (left < container.left + padding) this.handleMove([(container.left + padding) - left, 0])

    // Ensure width is correct, then reflow again to ensure position is in bounds
    if (width > container.width - padding * 2) {
      this.handleResize('E')([(container.width - padding * 2) - width, 0])
      reflowAgain = true
    }

    // Ensure height is correct, then reflow again to ensure position is in bounds
    if (height > container.height - padding * 2) {
      this.handleResize('S')([0, (container.height - padding * 2) - height])
      reflowAgain = true
    }

    if (reflowAgain && again) this.reflow({ again: false })
    this.props['template-slot']?.update(data => ({ ...data, style: this.base.getAttribute('style') }))
    ;(this.props['event-update'] ?? noop)(null, this)
  }

  unlock () {
    // Prevent unlocking twice
    if (this.state.alreadyUnlocked.get()) return
    this.state.alreadyUnlocked.set(true)

    const margin = {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }

    const stripMargin = element => {
      if (!element?.style) return
      if (element.matches('.handle, .no-export')) return

      const style = window.getComputedStyle(element)
      for (const dir in margin) {
        const value = parseFloat(style.getPropertyValue(`margin-${dir}`))
        if (value && !isNaN(value)) {
          margin[dir] += value
        }
      }
      element.style.margin = 0

      for (const child of element.childNodes) stripMargin(child)
    }

    window.requestAnimationFrame(() => {
      stripMargin(this.base)

      this.base.style.left = this.base.offsetLeft + margin.left + 'px'
      this.base.style.top = this.base.offsetTop + margin.top + 'px'
      this.base.style.position = 'absolute'

      // Remove children margin to ensure <Block> frame stick its content
      this.handleRelease()
    })
  }

  handleMove ([dx, dy]) {
    if (this.state.locked.get()) return
    if (this.state.frozen.get()) return

    const move = this.state.move.get()
    if (!move || move === 'none') return

    this.register()
    this.state.active.set(true)

    if (['both', 'horizontal'].includes(move)) {
      this.base.dataset.x = +(this.base.dataset.x ?? 0) + dx
      this.base.style.setProperty('--block-x', this.base.dataset.x + 'px')
    }

    if (['both', 'vertical'].includes(move)) {
      this.base.dataset.y = +(this.base.dataset.y ?? 0) + dy
      this.base.style.setProperty('--block-y', this.base.dataset.y + 'px')
    }

    this.props['template-slot']?.update(data => ({ ...data, style: this.base.getAttribute('style') }))
  }

  handleResize (direction) {
    return ([dx, dy]) => {
      if (this.state.locked.get()) return
      if (this.state.frozen.get()) return

      const resize = this.state.resize.get()
      if (!resize || resize === 'none') return

      this.register()
      this.state.active.set(true)

      const { width, height } = getScaledBoundingClientRect(this.base, Store.app.viewportScale.get())

      if (['both', 'horizontal'].includes(resize)) {
        if (direction.includes('W')) {
          this.base.dataset.x = +(this.base.dataset.x ?? 0) + dx
          this.base.style.setProperty('--block-x', this.base.dataset.x + 'px')
          this.base.style.width = width - dx + 'px'
        }

        if (direction.includes('E')) {
          this.base.style.width = width + dx + 'px'
        }
      }

      if (['both', 'vertical'].includes(resize)) {
        if (direction.includes('N')) {
          this.base.dataset.y = +(this.base.dataset.y ?? 0) + dy
          this.base.style.setProperty('--block-y', this.base.dataset.y + 'px')
          this.base.style.height = height - dy + 'px'
        }

        if (direction.includes('S')) {
          this.base.style.height = height + dy + 'px'
        }
      }

      if (resize === 'proportional') {
        const ratio = this.child?.ratio ?? 1
        this.base.style.width = width + dx + 'px'
        this.base.style.height = ((width + dx) / ratio) + 'px'
      }

      this.props['template-slot']?.update(data => ({ ...data, style: this.base.getAttribute('style') }))
    }
  }

  handleResetResize (direction) {
    return () => {
      if (this.state.locked.get()) return
      if (this.state.frozen.get()) return

      this.register()

      if (direction.includes('N')) {
        this.base.dataset.y = 0
        this.base.style.setProperty('--block-y', 0)
      }

      if (direction.includes('W')) {
        this.base.dataset.x = 0
        this.base.style.setProperty('--block-x', 0)
      }

      if (direction.includes('N') || direction.includes('S')) this.base.style.height = ''
      if (direction.includes('W') || direction.includes('E')) this.base.style.width = ''

      this.props['template-slot']?.update(data => ({ ...data, style: this.base.getAttribute('style') }))
      ;(this.props['event-update'] ?? noop)(null, this)
    }
  }

  handleRelease () {
    this.reflow()
    this.state.active.set(false)

    this.props['template-slot']?.update(data => ({ ...data, style: this.base.getAttribute('style') }))
    ;(this.props['event-update'] ?? noop)(null, this)
  }

  handleAnimation () {
    const animation = this.state.animation.get()
    let timing = this.state.animationTiming.get()

    // Ensure start and end are always sorted
    timing = (timing ?? [0, 1]).sort()

    if (animation) {
      this.base.dataset.animation = animation ?? ''
      this.base.dataset.animationTiming = JSON.stringify(timing) ?? '[0, 1]'
    } else {
      delete this.base.dataset.animation
      delete this.base.dataset.animationTiming
    }

    this.props['template-slot']?.update(data => ({
      ...data,
      animation: animation ? { value: animation, timing } : null
    }))
  }
}

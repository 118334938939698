import './Text.scss'
import { Component } from '@tooooools/ui'
import { readable, writable } from '@tooooools/ui/state'

import * as Tiptap from '@tiptap/core'
import TiptapBold from '@tiptap/extension-bold'
import TiptapDocument from '@tiptap/extension-document'
import TiptapFontSize from '/utils/tiptap-font-size-extension'
import TiptapHardBreak from '@tiptap/extension-hard-break'
import TiptapHeading from '@tiptap/extension-heading'
import TiptapHighlight from '@tiptap/extension-highlight'
import TiptapItalic from '@tiptap/extension-italic'
import TiptapParagraph from '@tiptap/extension-paragraph'
import TiptapText from '@tiptap/extension-text'
import TiptapTextAlign from '@tiptap/extension-text-align'
import TiptapTextStyle from '@tiptap/extension-text-style'
import TiptapUnderline from '@tiptap/extension-underline'

import Store from '/data/store'

import Block from '/components/Block'

import noop from '/utils/noop'

export default class TextBlockComponent extends Component {
  beforeRender () {
    this.captureEscapeKey = this.captureEscapeKey.bind(this)
    this.handleLeaveEdition = this.handleLeaveEdition.bind(this)
    this.handleDblClick = this.handleDblClick.bind(this)
    this.handleEditable = this.handleEditable.bind(this)
    this.handleTransaction = this.handleTransaction.bind(this)

    this.state = {
      type: readable('text'),

      isBold: writable(false),
      isItalic: writable(false),
      textAlign: writable('left'),
      fontSize: writable(null),

      editable: writable(false)
    }
  }

  template (props, state) {
    const child = props.children[0]
    if (!child) return

    const attributes = {}
    for (const attr of child.attributes) {
      if (attr.name === 'style') continue
      attributes[attr.name] = attr.value
    }

    this.refs.editor = new Tiptap.Editor({
      content: props['template-slot']?.get()?.innerHTML ?? child.outerHTML,
      editorProps: { attributes },
      extensions: [
        TiptapBold,
        TiptapDocument,
        TiptapFontSize,
        TiptapHardBreak,
        TiptapHeading,
        TiptapHighlight,
        TiptapItalic,
        TiptapParagraph,
        TiptapText,
        TiptapTextAlign.configure({
          defaultAlignment: window.getComputedStyle(child).getPropertyValue('text-align'),
          types: ['heading', 'paragraph'],
          alignments: ['left', 'right', 'center']
        }),
        TiptapUnderline,
        TiptapTextStyle
      ],
      editable: state.editable.get(),
      injectCSS: false,
      onBlur: () => {
        ;(this.props['event-blur'] ?? noop)(null, this)
        if (window.getSelection) window.getSelection().removeAllRanges()
        else if (document.selection) document.selection.empty()
      }
    })

    child.remove()
    return this.refs.editor.view.dom
  }

  afterMount () {
    this.base.classList.add('text')
    for (const attr in this.dataProps) this.base.setAttribute(attr, this.dataProps[attr])

    this.base.addEventListener('dblclick', this.handleDblClick)
    this.state.editable.subscribe(this.handleEditable)
    window.addEventListener('keyup', this.captureEscapeKey)

    // Leave <Text> edition on any context or block change. Not using Tiptap.onBlur
    // method because it is triggered when clicking a button
    Store.app.context.subscribe(this.handleLeaveEdition)
    Store.app.block.subscribe(this.handleLeaveEdition)
  }

  captureEscapeKey (e) {
    if (e.key !== 'Escape') return
    if (this.state.editable.get()) this.state.editable.set(false)
  }

  handleLeaveEdition () {
    if (!this.state.editable.get()) return

    this.state.editable.set(false)
    // Ensure leaving edition also unselect the parent <Block>
    Store.app.block.update(block => {
      if (block === this._parent) return null
      return block
    })
  }

  handleDblClick (e) {
    e.preventDefault()
    e.stopPropagation()

    if (this.state.editable.get()) return
    this.state.editable.set(true)
    this.refs.editor.chain().focus().run()
  }

  handleEditable () {
    const editable = this.state.editable.get()

    this.base.classList.toggle('is-editable', editable)
    this.refs.editor.setEditable(editable)

    // Bind transaction as late as possible to avoid false registration of parent <Block>
    this.refs.editor[editable ? 'on' : 'off']('transaction', this.handleTransaction)

    // Prevent parent Block to be resized or dragged during edition
    if (this._parent instanceof Block) this._parent.state.frozen.set(editable)
  }

  handleTransaction ({ transaction }) {
    if (this._parent instanceof Block) this._parent.register()

    this.state.isBold.set(this.refs.editor.isActive('bold'))
    this.state.isItalic.set(this.refs.editor.isActive('italic'))
    this.state.textAlign.update(textAlign => {
      for (const textAlign of ['left', 'right', 'center']) {
        if (this.refs.editor.isActive({ textAlign })) return textAlign
      }
      return 'left'
    })

    this.state.fontSize.update(fontSize => {
      const node = window.getSelection().focusNode?.parentNode
      return node
        ? parseInt(window.getComputedStyle(node).getPropertyValue('font-size'))
        : fontSize
    })

    // Ensure no action will scroll the parent element (try commenting this and
    // increasing the font-size so that the block overflows its parent)
    if (this._parent instanceof Block) {
      this._parent.base.offsetParent.scrollLeft = 0
      this._parent.base.offsetParent.scrollTop = 0
    }

    this.props['template-slot']?.update(data => ({ ...data, innerHTML: this.base.innerHTML }))

    ;(this.props['event-update'] ?? noop)(null, this)
  }

  beforeDestroy () {
    window.removeEventListener('keyup', this.captureEscapeKey)
    ;(this.props['event-update'] ?? noop)(null, this)
    this.refs.editor?.destroy()
    this.base.removeEventListener('dblclick', this.handleDblClick)
    Store.app.context.unsubscribe(this.handleLeaveEdition)
    Store.app.block.unsubscribe(this.handleLeaveEdition)
  }
}
